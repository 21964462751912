/* eslint-disable @typescript-eslint/no-explicit-any */
import { Map } from "lucide-react";
import { useEffect, useState } from "react";
import { RoadmapForm } from "./RoadmapForm";
import axios from "axios";
import { baseURL } from "../../../config";
import "./RoadmapSection.css";
import { useNavigate } from "react-router-dom";

export function RoadmapSection() {
  const userId = localStorage.getItem("userId");
  const [personalVisionAndGoals, setPersonalVisionAndGoals] = useState<any>({});
  const [blink, setBlink] = useState(false);
  const [loading, setLoading] = useState(true); // Default loader state
  const [hasTakenTest, setHasTakenTest] = useState<boolean>(false); 
  const navigate = useNavigate();
  const [completedGoals, setCompletedGoals] = useState<Set<number>>(new Set());

  useEffect(() => {
    const getDetails = async () => {
      try {
        const res = await axios.get(`${baseURL}/user/${userId}`);
        setPersonalVisionAndGoals(res.data.personalVisionAndGoals);
          setHasTakenTest(true); // Mark test as taken
      } catch (error) {
        console.error("Error fetching details:", error);
      } finally {
        setLoading(false); // Stop loader after data fetch
      }
    };

    getDetails(); // Fetch data on mount
  }, [userId]);

  const parseShortTermGoals = (
    goalsString: string | string[] | undefined | null
  ): string[] => {
    if (
      goalsString == null ||
      (Array.isArray(goalsString) && goalsString.length === 0)
    ) {
      return [];
    }

    if (Array.isArray(goalsString)) {
      goalsString = goalsString.join(" ");
    }

    return goalsString
      .split("\n\n")
      .filter((goal) => goal.trim() !== "")
      .map((goal) => goal.replace(/\*/g, "").trim());
  };

  const shortTermGoals = parseShortTermGoals(
    personalVisionAndGoals?.shortTermGoals
  );

  const handleCheckboxChange = (index: number) => {
    const newCompletedGoals = new Set(completedGoals);
    newCompletedGoals.add(index);
    setCompletedGoals(newCompletedGoals);
  
    // Persist completion state in localStorage with today's date
    const today = new Date().toISOString().split("T")[0];
    localStorage.setItem("completedGoals", JSON.stringify(Array.from(newCompletedGoals)));
    localStorage.setItem("completedGoalsDate", today);
  };
  

  useEffect(() => {
    const storedCompletedGoals = localStorage.getItem("completedGoals");
    const storedDate = localStorage.getItem("completedGoalsDate");
  
    const today = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
  
    if (storedDate !== today) {
      // If the stored date is not today, clear completed goals
      localStorage.removeItem("completedGoals");
      localStorage.setItem("completedGoalsDate", today); // Update date to today
      setCompletedGoals(new Set());
    } else if (storedCompletedGoals) {
      // If the stored date is today, load completed goals
      setCompletedGoals(new Set(JSON.parse(storedCompletedGoals)));
    }
  }, []);
  

  const roadmap = {
    "Short-term (1-3 months)": shortTermGoals,
    "Bounty Board": ["Coming Soon!"],
    "Job Openings": [
      "Sales",
      "Marketing",
      "E-commerce",
      "Journalism",
      "Content Creation",
      "Hotel Management Graduates",
      "Data Analytics",
      "Information Technology",
      "Accounts",
      "Legal",
      "Human Resources",
      "Internal Consulting",
      "Product Design",
      "Tool Making & Design",
      "Sourcing & Supply Chain",
      "Manufacturing & Quality Control",
      "Stores & Dispatch",
      "Executive Assistants",
      "Subject Matter Expert",
      "Influencer",
    ],
    "Incubations": ["21 days of Incubation"],
  };

  const [showForm, setShowForm] = useState(false);

  const handleFormSubmit = () => {
    setHasTakenTest(true); // Set the state to true once the form is submitted
    setShowForm(false); // Close the form
  };

  const handleIncubationClick = () => {
    setBlink(true);
    navigate("/21DaysIncubation");
    setTimeout(() => setBlink(false), 10000);
  };

  return (
    <section id="roadmap" className="mb-8 px-4 sm:px-6 lg:px-0">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-6 gap-4">
        <div className="flex items-center gap-4">
          <div className="bg-green-100 p-3 rounded-full">
            <Map className="w-6 h-6 text-green-600" />
          </div>
          <h2 className="text-xl sm:text-2xl font-semibold">Growth Roadmap</h2>
        </div>
      </div>

      {loading ? ( // Show loader until data is fetched
        <div className="flex justify-center items-center h-64">
          <div className="w-8 h-8 border-4 border-t-4 border-green-600 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {Object.entries(roadmap).map(([period, goals]) => {
            const isShortTerm = period === "Short-term (1-3 months)";
            const gridClass = isShortTerm
              ? "col-span-1 sm:col-span-2 lg:col-span-3"
              : "";

            return (
              <div
                key={period}
                className={`relative bg-white p-4 sm:p-6 rounded-3xl border border-gray-200 shadow-md ${gridClass}`}
              >
                {isShortTerm && hasTakenTest && (
                  <button
                    onClick={() => setShowForm(true)}
                    className="absolute top-2 right-2 px-3 py-1 bg-green-500 text-white rounded text-sm"
                  >
                    Take Test
                  </button>
                )}
                <h3 className="text-lg sm:text-xl font-semibold mb-4">
                  {period}
                </h3>
                <ul
                  className={`space-y-3 ${
                    goals.length > 5 ? "max-h-40 overflow-y-auto" : ""
                  }`}
                >
                  {goals.map((goal, index) => (
                    <li
                      key={index}
                      className="flex gap-2"
                      style={{ fontFamily: '"Poppins", sans-serif' }}
                    >
                      {isShortTerm && (
                        <input
                          type="checkbox"
                          checked={completedGoals.has(index)}
                          onChange={() => handleCheckboxChange(index)}
                          disabled={completedGoals.has(index)}
                        />
                      )}
                      <span
                        className={`text-gray-700 text-sm sm:text-base ${isShortTerm && completedGoals.has(index) ? 'line-through text-gray-400' : ''}`}
                      >
                        {goal}
                      </span>

                      {period === "Job Openings" && (
                        <button className="border-none bg-gradient-to-r from-red-500 via-yellow-500 to-purple-500 bg-clip-text text-transparent">
                          Apply Now
                        </button>
                      )}
                      {period === "Incubations" && (
                        <button
                          
                          className={`border-none bg-gradient-to-r  from-red-500 via-yellow-500 to-purple-500 bg-clip-text text-transparent ${
                            blink ? "animate-blink" : ""
                          }`}
                          onClick={handleIncubationClick}
                        >
                          Enroll Now!
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      )}

      {showForm && (
        <RoadmapForm
          onSubmit={handleFormSubmit}
          onClose={() => setShowForm(false)}
        />
      )}
    </section>
  );
}
