/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Heart, Moon, Droplet, Activity, Flower } from 'lucide-react';
import axios from 'axios';
import { baseURL } from '../../../config';
import { HealthAssessmentForm } from './HealthSectionForm';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';
import ReactMarkdown from 'react-markdown';

interface HealthMetrics {
  bmi: number;
  sleep: number;
  hydration: number;
  activity: number;
}

export function HealthSection() {
  const userId = localStorage.getItem('userId');
  const [data, setData] = useState<Record<string, any>>({ waterIntake: 0 });
  const [hasTakenTest, setHasTakenTest] = useState<boolean>(false); 
  const [showAssessment, setShowAssessment] = useState<boolean>(false);
  const [details,setDetails] = useState<Record<string,any>>([]);
  const [showContent,setShowContent]=useState('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const dailyIntake = data.waterIntake ? Math.ceil(data.waterIntake / 33.814) : 0;
  const [loadingMeditations, setLoadingMeditations] = useState(true); // Loader for meditations
  const [loadingReadMore, setLoadingReadMore] = useState(false);

  const calculateBMI = (weight: number, height: number): number => {
    if (weight > 0 && height > 0) {
      const heightInMetres = height / 100;
      return weight / (heightInMetres * heightInMetres); // Return as a number
    }
    return 0;
  };
  
  useEffect(() => {
    const weight = parseFloat(data.weight);
    const height = parseFloat(data.height);
  
    let bmi = 0;
    if (!isNaN(weight) && !isNaN(height)) {
      bmi = calculateBMI(weight, height);
    }
    setData((prevData) => ({
      ...prevData,
      bmi: Math.floor(bmi), // Ensure bmi is a number
    }));
  }, [data.weight, data.height]);
  

  const healthMetrics: HealthMetrics = {
    bmi: data.bmi || 0,
    sleep: data.sleepHours || 0,
    hydration: dailyIntake,
    activity: data.exerciseFrequency || 0,
  };

  const getHealthStatus = (bmi: number): string => {
    if (bmi < 18.5) return 'Underweight';
    if (bmi < 25) return 'Healthy';
    if (bmi < 30) return 'Overweight';
    return 'Obese';
  };

  useEffect(() => {
    if (userId) {
      getDetails();
    }
  }, [userId]);

  const getDetails = async () => {
    if (!userId) return;

    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      if (response.data?.healthAssessment) {
        setData(response.data.healthAssessment);
        getMindfulness(response.data.JudgmentalAssessments);
        setHasTakenTest(true); 
      } else {
        setHasTakenTest(false); 
      }
    } catch (error) {
      console.error(error);
      setHasTakenTest(false); 
    }
  };

  const handleTakeTestClick = () => {
    setShowAssessment(true); 
  };

 

  const getMindfulness = async (data: any) => {
    try {
      const bigFiveScore = data?.bigFiveScore || null;
      const trait = data?.mbtiScore?.traits?.[0] || null;

      if (bigFiveScore && trait) {
        const response = await axios.post(`${baseURL}/user/meditations-short`, {
          bigFiveScore,
          trait,
        });

        const responseArray = response.data
          .split("\n")
          .filter((line: any) => line.trim() !== "");
        setDetails(responseArray);
      } else {
        setDetails(["Take the personality test to see your personalized recommendations."]);
      }
    } catch (error) {
      console.error("Error in getMindfulness:", error);
      setDetails(["Unable to load content. Please try again later."]);
    } finally {
      setLoadingMeditations(false);
    }
  };

//   function parseMarkdownToHTML(markdown: string): string {
//     return markdown
//         .replace(/^## (.*$)/gm, '<h2>$1</h2>') // Convert H2 headings
//         .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Convert bold text
//         // Process unordered lists
//         .replace(/((?:^|\n)\* [^\n]*)+/g, (match) => {
//             const items = match.split(/\n/).filter(line => line.startsWith('* '));
//             const lis = items.map(item => `<li>${item.substring(2)}</li>`).join('');
//             return `<ul>${lis}</ul>`;
//         })
//         // Process ordered lists
//         .replace(/((?:^|\n)\d+\.\s[^\n]*)+/g, (match) => {
//             const items = match.split(/\n/).filter(line => /^\d+\.\s/.test(line));
//             const lis = items.map(item => `<li>${item.replace(/^\d+\.\s/, '')}</li>`).join('');
//             return `<ol>${lis}</ol>`;
//         })
//         .replace(/\n/g, '<br>'); // Convert new lines to breaks
// }

  const handleReadMore = async () => {
    setLoadingReadMore(true); // Start loader
    try {
      const response = await axios.post(`${baseURL}/user/meditations`, details);;
      setShowContent(response.data);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching meditation details:", error);
    } finally {
      setLoadingReadMore(false); // Stop loader
    }
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
    setShowContent("");
  };
  

  return (
    <section id="health" className="mb-8 px-4 sm:px-6 lg:px-0">
      <div className="flex items-center gap-4 mb-6">
        <div className="bg-red-100 p-3 rounded-full">
          <Heart className="w-6 h-6 text-red-600" />
        </div>
        <h2 className="text-2xl font-semibold flex items-center">
          Health is wealth; nurture it well.
          {!hasTakenTest && (
            <button
              className="ml-4 text-sm text-white bg-red-600 px-3 py-1 rounded"
              onClick={handleTakeTestClick}
            >
              Take Test
            </button>
          )}
        </h2>
      </div>

      {showAssessment && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-10">
          <HealthAssessmentForm
  onSubmit={async (data: any) => {
    const payload = {
      healthAssessment: data
    };
    try {
      await axios.put(`${baseURL}/user/submit-data/${userId}`, payload);

      // Fetch updated data immediately after successful submission
      const response = await axios.get(`${baseURL}/user/${userId}`);
      if (response.data?.healthAssessment) {
        setData(response.data.healthAssessment); // Update state with new data
        getMindfulness(response.data.JudgmentalAssessments); // Refresh mindfulness details
        setHasTakenTest(true);
      } else {
        setHasTakenTest(false);
      }

      setShowAssessment(false); // Close the assessment modal
    } catch (error) {
      console.error(error);
    }
  }}
  onClose={() => setShowAssessment(false)}
/>
        </div>
      )}

<div className="sm:grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
  {/* For mobile view, use Swiper */}
  <Swiper
    spaceBetween={16}
    slidesPerView={1}
    className="block sm:hidden"
    modules={[Autoplay]} loop={false} autoplay={{delay: 3000,disableOnInteraction: true}}
  >
       <SwiperSlide>
      <div className={`relative bg-white p-6 shadow-md rounded-3xl border border-gray-200 ${hasTakenTest ? '' : 'opacity-50'}`}>
        <div className="flex items-center gap-2 mb-2">
          <Activity className="w-5 h-5 text-gray-600" />
          <h3 className="font-medium">BMI</h3>
        </div>
        <p className="text-2xl font-semibold">{healthMetrics.bmi}</p>
        <p className="text-sm text-gray-500">{getHealthStatus(healthMetrics.bmi)}</p>
        
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className={`relative bg-white p-6 shadow-md rounded-3xl border border-gray-200 ${hasTakenTest ? '' : 'opacity-50'}`}>
        <div className="flex items-center gap-2 mb-2">
          <Moon className="w-5 h-5 text-gray-600" />
          <h3 className="font-medium">Sleep</h3>
        </div>
        <p className="text-2xl font-semibold">{healthMetrics.sleep}</p>
        <p className="text-sm text-gray-500">Average  daily</p>  
      </div>
    </SwiperSlide>
    <SwiperSlide>   <div className={`relative bg-white p-6 shadow-md rounded-3xl border border-gray-200 ${hasTakenTest ? '' : 'opacity-50'}`}>
          <div className="flex items-center gap-2 mb-2">
            <Droplet className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">Hydration</h3>
          </div>
          <p className="text-2xl font-semibold">{healthMetrics.hydration} liter</p>
          <p className="text-sm text-gray-500">Daily intake</p>
        </div>
        </SwiperSlide>
    <SwiperSlide>    
       <div className={`relative bg-white p-6 shadow-md rounded-3xl border border-gray-200 ${hasTakenTest ? '' : 'opacity-50'}`}>
          <div className="flex items-center gap-2 mb-2">
            <Activity className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">Activity</h3>
          </div>
          <p className="text-2xl font-semibold">{healthMetrics.activity}</p>
          <p className="text-sm text-gray-500">Execrise</p>  
        </div>
        </SwiperSlide>
    <SwiperSlide>   <div className={`relative bg-white p-6 shadow-md rounded-3xl border border-gray-200 ${hasTakenTest ? '' : 'opacity-50'}`}>
          <div className="flex items-center gap-2 mb-2">
            <Flower className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">Mindfulness</h3>
          </div>
          {details.map((el:any,index:number)=>
            <p key={index}>{el}</p>
          )}
          <p className="text-sm text-gray-500">Techniques</p>
          {hasTakenTest && (
            <button className="absolute bottom-2 right-2 px-3 py-1 rounded text-sm text-white bg-red-600" onClick={handleReadMore}>
              Read More
            </button>
          )}
        </div></SwiperSlide>
  </Swiper>
  </div>
  <div className="hidden sm:grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className={`relative bg-white p-6 shadow-md rounded-3xl border border-gray-200 ${hasTakenTest ? '' : 'opacity-50'}`}>
          <div className="flex items-center gap-2 mb-2">
            <Activity className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">BMI</h3>
          </div>
          <p className="text-2xl font-semibold">{healthMetrics.bmi}</p>
          <p className="text-sm text-gray-500">{getHealthStatus(healthMetrics.bmi)}</p>
          
        </div>

        <div className={`relative bg-white p-6 shadow-md rounded-3xl border border-gray-200 ${hasTakenTest ? '' : 'opacity-50'}`}>
          <div className="flex items-center gap-2 mb-2">
            <Moon className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">Sleep</h3>
          </div>
          <p className="text-2xl font-semibold">{healthMetrics.sleep}</p>
          <p className="text-sm text-gray-500">Average daily</p>
          
        </div>

        <div className={`relative bg-white p-6 shadow-md rounded-3xl border border-gray-200 ${hasTakenTest ? '' : 'opacity-50'}`}>
          <div className="flex items-center gap-2 mb-2">
            <Droplet className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">Hydration</h3>
          </div>
          <p className="text-2xl font-semibold">{healthMetrics.hydration} liters</p>
          <p className="text-sm text-gray-500">Daily intake</p>
          
        </div>

        <div className={`relative bg-white p-6 shadow-md rounded-3xl border border-gray-200 ${hasTakenTest ? '' : 'opacity-50'}`}>
          <div className="flex items-center gap-2 mb-2">
            <Activity className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">Activity</h3>
          </div>
          <p className="text-2xl font-semibold">{healthMetrics.activity}</p>
          <p className="text-sm text-gray-500">Excerise</p>
        </div>

        <div className={`relative bg-white p-6 shadow-md rounded-3xl border border-gray-200 ${hasTakenTest ? '' : 'opacity-50'}`}>
          <div className="flex items-center gap-2 mb-2">
            <Flower className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">Mindfulness</h3>
          </div>
          {loadingMeditations ? (
        <div className="flex justify-center items-center">
          <div className="loader">Loading...</div>
        </div>
      ) : (
        <div className="meditations mb-2">
          {details.map((detail:any, index:number) => (
            <p key={index}>{detail}</p>
          ))}
          <button
            className={`text-white bg-red-600 absolute mt-2 bottom-1 right-2 px-3 py-1 rounded text-sm ${loadingReadMore ? "opacity-50" : ""}`}
            onClick={handleReadMore}
            disabled={loadingReadMore}
          >
            {loadingReadMore ? "Loading..." : "Read More"}
          </button>
        </div>
      )}
        </div>
      </div>
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-h-[90vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4">Meditation Details</h2>
            <ReactMarkdown className="text-sm whitespace-pre-wrap">{showContent}</ReactMarkdown>
            <button
              className="mt-4 text-sm text-white bg-red-600 px-4 py-2 rounded"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
}