import { CoreSkillsQuestion } from '../../../utils/types';
import { EQQuestions, Question } from './type';



export const iqQuestions: Question[] = [
    {
        "question": "What comes next in the sequence? 2, 6, 12, 20, 30, ?",
        "options": ["42", "40", "36", "56"],
        "correctAnswer": "42"
      },
      {
        "question": "If 5 apples cost $1.25, what will 8 apples cost?",
        "options": ["$2.00", "$2.25", "$2.50", "$2.75"],
        "correctAnswer": "$2.00"
      },
      {
        "question": "Find the missing number: 7, 14, 28, ?, 112.",
        "options": ["56", "42", "36", "72"],
        "correctAnswer": "56"
      },
      {
        "question": "What is the next letter in the pattern? A, C, F, J, ?",
        "options": ["K", "N", "O", "P"],
        "correctAnswer": "O"
      },
      {
        "question": "Solve the analogy: Book is to Read as Knife is to?",
        "options": ["Cut", "Write", "Measure", "Cook"],
        "correctAnswer": "Cut"
      },
      {
        "question": "What is 15% of 200?",
        "options": ["25", "30", "20", "35"],
        "correctAnswer": "30"
      },
      {
        "question": "If 3 cats catch 3 mice in 3 minutes, how many cats are needed to catch 100 mice in 100 minutes?",
        "options": ["3", "33", "100", "1"],
        "correctAnswer": "3"
      },
      {
        "question": "What is the missing number? 9, 16, 25, ?, 49.",
        "options": ["30", "36", "39", "44"],
        "correctAnswer": "36"
      },
      {
        "question": "Which word does not belong? Cat, Dog, Bird, Carrot.",
        "options": ["Cat", "Dog", "Bird", "Carrot"],
        "correctAnswer": "Carrot"
      },
      {
        "question": "If it takes 5 machines 5 minutes to make 5 widgets, how long would it take 100 machines to make 100 widgets?",
        "options": ["5 minutes", "100 minutes", "50 minutes", "10 minutes"],
        "correctAnswer": "5 minutes"
      },
      {
        "question": "What is 45% of 80?",
        "options": ["36", "32", "40", "48"],
        "correctAnswer": "36"
      },
      {
        "question": "Which number is a prime number?",
        "options": ["9", "15", "23", "21"],
        "correctAnswer": "23"
      },
      {
        "question": "Solve the analogy: Fish is to Swim as Bird is to?",
        "options": ["Fly", "Walk", "Run", "Sing"],
        "correctAnswer": "Fly"
      },
      {
        "question": "What comes next in the series? 3, 9, 27, ?",
        "options": ["54", "81", "36", "45"],
        "correctAnswer": "81"
      },
      {
        "question": "What is the square root of 144?",
        "options": ["11", "12", "14", "16"],
        "correctAnswer": "12"
      },
      {
        "question": "Which word is the odd one out? Table, Chair, Bed, Apple.",
        "options": ["Table", "Chair", "Bed", "Apple"],
        "correctAnswer": "Apple"
      },
      {
        "question": "What comes next in the series? 2, 3, 5, 7, 11, ?",
        "options": ["12", "13", "15", "17"],
        "correctAnswer": "13"
      },
      {
        "question": "What is the area of a rectangle with a length of 10 and a width of 5?",
        "options": ["15", "30", "50", "60"],
        "correctAnswer": "50"
      },
      {
        "question": "Which number is not a multiple of 4?",
        "options": ["16", "24", "32", "33"],
        "correctAnswer": "33"
      },
      {
        "question": "Find the missing number: 81, 27, 9, 3, ?",
        "options": ["1", "2", "0", "6"],
        "correctAnswer": "1"
      },
      {
        "question": "What is the next letter in the pattern? Z, X, V, T, ?",
        "options": ["S", "R", "P", "Q"],
        "correctAnswer": "R"
      },
      {
        "question": "Which number comes next in the series? 4, 8, 16, 32, ?",
        "options": ["48", "64", "56", "72"],
        "correctAnswer": "64"
      },
      {
        "question": "Solve the analogy: Water is to Drink as Food is to?",
        "options": ["Cook", "Eat", "Serve", "Grow"],
        "correctAnswer": "Eat"
      },
      {
        "question": "What comes next in the series? 2, 4, 8, 16, ?",
        "options": ["18", "24", "32", "64"],
        "correctAnswer": "32"
      },
      {
        "question": "Which of these numbers is a square number?",
        "options": ["12", "15", "16", "18"],
        "correctAnswer": "16"
      },
      {
        "question": "What comes next in the pattern? 5, 10, 20, 40, ?",
        "options": ["45", "80", "60", "70"],
        "correctAnswer": "80"
      },
      {
        "question": "What is the missing number in the sequence? 3, 5, 8, 13, ?",
        "options": ["18", "20", "21", "22"],
        "correctAnswer": "21"
      },
      {
        "question": "Which letter is the odd one out? A, E, I, O, X.",
        "options": ["A", "E", "O", "X"],
        "correctAnswer": "X"
      },
      {
        "question": "What is the result of 25 + 35 - 15?",
        "options": ["35", "45", "55", "50"],
        "correctAnswer": "45"
      },
      {
        "question": "Which number is not divisible by 3?",
        "options": ["9", "15", "22", "27"],
        "correctAnswer": "22"
      },
      {
        "question": "Solve the analogy: Clock is to Time as Thermometer is to?",
        "options": ["Temperature", "Speed", "Weight", "Volume"],
        "correctAnswer": "Temperature"
      },
      {
        "question": "What is 20% of 150?",
        "options": ["25", "30", "35", "40"],
        "correctAnswer": "30"
      },
      {
        "question": "What is 3 to the power of 4?",
        "options": ["27", "64", "81", "243"],
        "correctAnswer": "81"
      },
      {
        "question": "What is the missing number? 10, 20, 30, ?, 50.",
        "options": ["35", "40", "45", "50"],
        "correctAnswer": "40"
      },
      {
        "question": "What is the capital of France?",
        "options": ["Berlin", "Madrid", "Paris", "Rome"],
        "correctAnswer": "Paris"
      },
      {
        "question": "Which of these shapes has three sides?",
        "options": ["Square", "Triangle", "Circle", "Pentagon"],
        "correctAnswer": "Triangle"
      },
      {
        "question": "Which word is the odd one out? Dog, Cat, Lion, Elephant.",
        "options": ["Dog", "Cat", "Lion", "Elephant"],
        "correctAnswer": "Dog"
      },
      {
        "question": "If a train travels 60 miles per hour, how far will it go in 2 hours?",
        "options": ["100 miles", "120 miles", "150 miles", "180 miles"],
        "correctAnswer": "120 miles"
      },
      {
        "question": "What is the smallest prime number?",
        "options": ["0", "1", "2", "3"],
        "correctAnswer": "2"
      },
      {
        "question": "What is the value of 8 × 7?",
        "options": ["48", "56", "64", "72"],
        "correctAnswer": "56"
      },
      {
        "question": "If a clock shows 3:15, what angle is formed between the hour and minute hands?",
        "options": ["0°", "37.5°", "45°", "90°"],
        "correctAnswer": "37.5°"
      },
      {
        "question": "What comes next in the series? 101, 103, 107, 109, ?",
        "options": ["111", "113", "115", "119"],
        "correctAnswer": "113"
      },
      {
        "question": "Which of the following is not a primary color?",
        "options": ["Red", "Green", "Blue", "Yellow"],
        "correctAnswer": "Green"
      },
      {
        "question": "Solve the analogy: Hand is to Glove as Foot is to?",
        "options": ["Hat", "Shoe", "Sandal", "Sock"],
        "correctAnswer": "Shoe"
      },
      {
        "question": "What is 75% of 200?",
        "options": ["100", "125", "150", "175"],
        "correctAnswer": "150"
      },
      {
        "question": "What is the cube of 3?",
        "options": ["9", "18", "27", "36"],
        "correctAnswer": "27"
      },
      {
        "question": "Which word is the odd one out? Circle, Square, Triangle, Rectangle.",
        "options": ["Circle", "Square", "Triangle", "Rectangle"],
        "correctAnswer": "Circle"
      },
      {
        "question": "What comes next in the sequence? 2, 4, 8, 16, 32, ?",
        "options": ["48", "64", "96", "128"],
        "correctAnswer": "64"
      },
      {
        "question": "If a car travels at 60 km/h, how far will it travel in 30 minutes?",
        "options": ["15 km", "20 km", "30 km", "40 km"],
        "correctAnswer": "30 km"
      },
      {
        "question": "Solve the analogy: Eye is to See as Ear is to?",
        "options": ["Listen", "Speak", "Touch", "Smell"],
        "correctAnswer": "Listen"
      }
];

export const coreSkillsQuestions: CoreSkillsQuestion[] = [
  {
    "category": "Problem Solving",
    "question": "What is the first step in solving a complex problem?",
    "options": ["a) Implementing a solution", "b) Identifying the problem", "c) Brainstorming ideas", "d) Testing potential solutions"],
    "correctAnswer": "b) Identifying the problem"
  },
  {
    "category": "Problem Solving",
    "question": "Which technique is most effective for breaking down a large problem into smaller manageable parts?",
    "options": ["a) Brainstorming", "b) Root cause analysis", "c) Problem decomposition", "d) SWOT analysis"],
    "correctAnswer": "c) Problem decomposition"
  },
  {
    "category": "Problem Solving",
    "question": "What does the acronym 'SMART' stand for in problem-solving goals?",
    "options": ["a) Simple, Measurable, Accurate, Realistic, Timely", "b) Specific, Measurable, Achievable, Relevant, Time-bound", "c) Strategic, Motivational, Attainable, Reliable, Timely", "d) None of the above"],
    "correctAnswer": "b) Specific, Measurable, Achievable, Relevant, Time-bound"
  },
  {
    "category": "Problem Solving",
    "question": "Which method is used to identify the root cause of a problem?",
    "options": ["a) Ishikawa Diagram", "b) Gantt Chart", "c) Brainwriting", "d) Kanban"],
    "correctAnswer": "a) Ishikawa Diagram"
  },
  {
    "category": "Problem Solving",
    "question": "Which of these tools is typically used in decision-making during problem solving?",
    "options": ["a) PERT Chart", "b) Decision Matrix", "c) Flow Diagram", "d) Pareto Chart"],
    "correctAnswer": "b) Decision Matrix"
  },
  {
    "category": "Problem Solving",
    "question": "What is the primary goal of brainstorming?",
    "options": ["a) Generating a wide range of ideas", "b) Choosing the best idea", "c) Testing hypotheses", "d) Documenting the process"],
    "correctAnswer": "a) Generating a wide range of ideas"
  },
  {
    "category": "Problem Solving",
    "question": "What does root cause analysis aim to achieve?",
    "options": ["a) Fixing symptoms", "b) Identifying underlying issues", "c) Testing possible solutions", "d) Brainstorming alternatives"],
    "correctAnswer": "b) Identifying underlying issues"
  },
  {
    "category": "Problem Solving",
    "question": "Which of the following represents a quantitative decision-making tool?",
    "options": ["a) SWOT analysis", "b) Cost-benefit analysis", "c) Brainstorming", "d) Fishbone diagram"],
    "correctAnswer": "b) Cost-benefit analysis"
  },
  {
    "category": "Problem Solving",
    "question": "What is the key benefit of creating a flowchart?",
    "options": ["a) It predicts outcomes", "b) It visualizes processes", "c) It eliminates risks", "d) It tests assumptions"],
    "correctAnswer": "b) It visualizes processes"
  },
  {
    "category": "Problem Solving",
    "question": "What is the role of critical thinking in problem solving?",
    "options": ["a) Generating creative ideas", "b) Analyzing and evaluating ideas", "c) Documenting the solution", "d) Implementing solutions"],
    "correctAnswer": "b) Analyzing and evaluating ideas"
  },
  {
    "category": "Communication",
    "question": "Which is the most effective way to give constructive feedback?",
    "options": ["a) Using vague terms", "b) Being specific and actionable", "c) Criticizing the person", "d) Avoiding difficult topics"],
    "correctAnswer": "b) Being specific and actionable"
  },
  {
    "category": "Communication",
    "question": "What does active listening involve?",
    "options": ["a) Thinking about your response", "b) Interrupting to clarify", "c) Fully concentrating, understanding, and responding thoughtfully", "d) Nodding without understanding"],
    "correctAnswer": "c) Fully concentrating, understanding, and responding thoughtfully"
  },
  {
    "category": "Communication",
    "question": "Which of the following is an example of non-verbal communication?",
    "options": ["a) Writing an email", "b) Speaking in a meeting", "c) Maintaining eye contact", "d) Making a phone call"],
    "correctAnswer": "c) Maintaining eye contact"
  },
  {
    "category": "Communication",
    "question": "What is the key to effective communication?",
    "options": ["a) Talking as much as possible", "b) Using complex language", "c) Ensuring clarity and understanding", "d) Focusing only on your message"],
    "correctAnswer": "c) Ensuring clarity and understanding"
  },
  {
    "category": "Communication",
    "question": "Which type of communication is most suitable for resolving a conflict?",
    "options": ["a) Text messages", "b) Face-to-face communication", "c) Email correspondence", "d) Social media"],
    "correctAnswer": "b) Face-to-face communication"
  },
  {
    "category": "Communication",
    "question": "What is the primary goal of persuasive communication?",
    "options": ["a) Informing the audience", "b) Convincing others to take action", "c) Sharing personal opinions", "d) Avoiding disagreements"],
    "correctAnswer": "b) Convincing others to take action"
  },
  {
    "category": "Communication",
    "question": "Which element is most crucial in effective written communication?",
    "options": ["a) Handwriting", "b) Structure and clarity", "c) Length", "d) Technical terms"],
    "correctAnswer": "b) Structure and clarity"
  },
  {
    "category": "Communication",
    "question": "What does the term 'noise' refer to in the communication process?",
    "options": ["a) Sound disturbances", "b) Distractions or barriers to understanding", "c) Lengthy messages", "d) Overly complex vocabulary"],
    "correctAnswer": "b) Distractions or barriers to understanding"
  },
  {
    "category": "Communication",
    "question": "What is one key feature of assertive communication?",
    "options": ["a) Being aggressive", "b) Respecting both your needs and others'", "c) Avoiding confrontation", "d) Speaking softly"],
    "correctAnswer": "b) Respecting both your needs and others'"
  },
  {
    "category": "Communication",
    "question": "How can feedback be made more effective?",
    "options": ["a) Delivering it immediately", "b) Providing only positive remarks", "c) Avoiding any suggestions", "d) Focusing on the person instead of behavior"],
    "correctAnswer": "a) Delivering it immediately"
  },
  {
    "category": "Leadership",
    "question": "Which leadership style involves making decisions collaboratively with team members?",
    "options": ["a) Autocratic", "b) Democratic", "c) Laissez-faire", "d) Transactional"],
    "correctAnswer": "b) Democratic"
  },
  {
    "category": "Leadership",
    "question": "What is emotional intelligence in leadership?",
    "options": ["a) The ability to work under pressure", "b) Understanding and managing your emotions and others’ emotions", "c) Knowing technical details about the job", "d) Making quick decisions without consulting"],
    "correctAnswer": "b) Understanding and managing your emotions and others’ emotions"
  },
  {
    "category": "Leadership",
    "question": "Which skill is most important for a leader to inspire their team?",
    "options": ["a) Creativity", "b) Vision", "c) Delegation", "d) Technical expertise"],
    "correctAnswer": "b) Vision"
  },
  {
    "category": "Leadership",
    "question": "A transformational leader primarily focuses on:",
    "options": ["a) Routine tasks", "b) Encouraging innovation and motivation", "c) Punishing errors", "d) Following strict rules"],
    "correctAnswer": "b) Encouraging innovation and motivation"
  },
  {
    "category": "Leadership",
    "question": "What is one key aspect of servant leadership?",
    "options": ["a) Prioritizing the needs of the leader", "b) Serving the needs of the team", "c) Avoiding accountability", "d) Ignoring team opinions"],
    "correctAnswer": "b) Serving the needs of the team"
  },
  {
    "category": "Leadership",
    "question": "Which of the following defines situational leadership?",
    "options": ["a) Applying one style of leadership", "b) Adapting leadership based on the situation and people", "c) Avoiding decision-making", "d) Delegating everything"],
    "correctAnswer": "b) Adapting leadership based on the situation and people"
  },
  {
    "category": "Leadership",
    "question": "What is the best way for leaders to handle team conflict?",
    "options": ["a) Ignoring it", "b) Addressing it openly and collaboratively", "c) Punishing team members", "d) Taking sides"],
    "correctAnswer": "b) Addressing it openly and collaboratively"
  },
  {
    "category": "Leadership",
    "question": "Which quality is essential for a leader to build trust?",
    "options": ["a) Consistency in actions", "b) Avoiding difficult decisions", "c) Giving vague instructions", "d) Leading without feedback"],
    "correctAnswer": "a) Consistency in actions"
  },
  {
    "category": "Leadership",
    "question": "What is the purpose of a leader delegating tasks?",
    "options": ["a) To avoid responsibility", "b) To empower team members and optimize efficiency", "c) To reduce workload temporarily", "d) To pass off difficult tasks"],
    "correctAnswer": "b) To empower team members and optimize efficiency"
  },
  {
    "category": "Leadership",
    "question": "Which of the following is an example of a visionary leader?",
    "options": ["a) Someone focused only on current results", "b) Someone inspiring long-term goals and strategy", "c) Someone avoiding innovation", "d) Someone prioritizing technical skills over people management"],
    "correctAnswer": "b) Someone inspiring long-term goals and strategy"
  },
  {
    "category": "Leadership",
    "question": "How should a leader handle failure?",
    "options": ["a) Blame others", "b) Take responsibility and learn from it", "c) Ignore it and move on", "d) Avoid discussing it"],
    "correctAnswer": "b) Take responsibility and learn from it"
  },
  {
    "category": "Technical Skills",
    "question": "What does HTML stand for?",
    "options": [
      "a) HyperText Markup Language",
      "b) HighText Machine Language",
      "c) Hyperlink Markup Language",
      "d) HyperTransfer Markup Language"
    ],
    "correctAnswer": "a) HyperText Markup Language"
  },
  {
    "category": "Technical Skills",
    "question": "Which of the following is a version control system?",
    "options": ["a) Git", "b) Java", "c) Docker", "d) Kubernetes"],
    "correctAnswer": "a) Git"
  },
  {
    "category": "Technical Skills",
    "question": "Which command is used to check the current branch in Git?",
    "options": ["a) git branch", "b) git checkout", "c) git pull", "d) git log"],
    "correctAnswer": "a) git branch"
  },
  {
    "category": "Technical Skills",
    "question": "What is the time complexity of binary search?",
    "options": ["a) O(n)", "b) O(log n)", "c) O(n log n)", "d) O(1)"],
    "correctAnswer": "b) O(log n)"
  },
  {
    "category": "Technical Skills",
    "question": "What is the purpose of a database index?",
    "options": [
      "a) To add duplicate data",
      "b) To improve query performance",
      "c) To delete unnecessary rows",
      "d) To create backup copies"
    ],
    "correctAnswer": "b) To improve query performance"
  },
  {
    "category": "Technical Skills",
    "question": "Which of these is NOT a programming paradigm?",
    "options": ["a) Object-Oriented", "b) Functional", "c) Declarative", "d) Index-Based"],
    "correctAnswer": "d) Index-Based"
  },
  {
    "category": "Technical Skills",
    "question": "What is the primary use of Docker?",
    "options": [
      "a) Automating repetitive tasks",
      "b) Managing virtual machines",
      "c) Running applications in lightweight containers",
      "d) Monitoring server performance"
    ],
    "correctAnswer": "c) Running applications in lightweight containers"
  },
  {
    "category": "Technical Skills",
    "question": "Which of these is a relational database?",
    "options": ["a) MongoDB", "b) MySQL", "c) Cassandra", "d) Redis"],
    "correctAnswer": "b) MySQL"
  },
  {
    "category": "Technical Skills",
    "question": "What does the acronym API stand for?",
    "options": [
      "a) Application Programming Interface",
      "b) Automated Processing Integration",
      "c) Advanced Programming Instruction",
      "d) Application Performance Index"
    ],
    "correctAnswer": "a) Application Programming Interface"
  },
  {
    "category": "Technical Skills",
    "question": "What is the default port for HTTP?",
    "options": ["a) 80", "b) 443", "c) 22", "d) 3306"],
    "correctAnswer": "a) 80"
  },
  {
    "category": "Creativity",
    "question": "What is the most important aspect of brainstorming?",
    "options": [
      "a) Criticizing each idea",
      "b) Generating as many ideas as possible",
      "c) Focusing on one idea",
      "d) Finalizing ideas during the session"
    ],
    "correctAnswer": "b) Generating as many ideas as possible"
  },
  {
    "category": "Creativity",
    "question": "What is lateral thinking?",
    "options": [
      "a) Solving problems using logical steps",
      "b) Thinking creatively by approaching problems from new angles",
      "c) Organizing thoughts linearly",
      "d) Copying existing solutions"
    ],
    "correctAnswer": "b) Thinking creatively by approaching problems from new angles"
  },
  {
    "category": "Creativity",
    "question": "Which activity best stimulates creativity?",
    "options": [
      "a) Engaging in routine tasks",
      "b) Exploring diverse experiences",
      "c) Repeating previous solutions",
      "d) Avoiding new challenges"
    ],
    "correctAnswer": "b) Exploring diverse experiences"
  },
  {
    "category": "Creativity",
    "question": "What is the main purpose of a mood board?",
    "options": [
      "a) Defining technical requirements",
      "b) Visualizing creative inspiration",
      "c) Documenting detailed project steps",
      "d) Tracking financial goals"
    ],
    "correctAnswer": "b) Visualizing creative inspiration"
  },
  {
    "category": "Creativity",
    "question": "Which technique involves quickly sketching ideas to visualize solutions?",
    "options": ["a) Mind mapping", "b) Rapid prototyping", "c) Storyboarding", "d) Wireframing"],
    "correctAnswer": "c) Storyboarding"
  },
  {
    "category": "Creativity",
    "question": "What does SCAMPER stand for in creativity techniques?",
    "options": [
      "a) Substitute, Combine, Adapt, Modify, Put to another use, Eliminate, Rearrange",
      "b) Sketch, Create, Apply, Manage, Plan, Evaluate, Repeat",
      "c) Solve, Critique, Analyze, Measure, Prototype, Eliminate, Reorganize",
      "d) None of the above"
    ],
    "correctAnswer": "a) Substitute, Combine, Adapt, Modify, Put to another use, Eliminate, Rearrange"
  },
  {
    "category": "Creativity",
    "question": "What is one benefit of divergent thinking?",
    "options": [
      "a) Focus on single solutions",
      "b) Generating multiple unique ideas",
      "c) Prioritizing practical solutions",
      "d) Avoiding unnecessary exploration"
    ],
    "correctAnswer": "b) Generating multiple unique ideas"
  },
  {
    "category": "Creativity",
    "question": "Which of the following is a characteristic of creative thinking?",
    "options": ["a) Fixed mindset", "b) Open-mindedness", "c) Strict adherence to rules", "d) Avoidance of risks"],
    "correctAnswer": "b) Open-mindedness"
  },
  {
    "category": "Creativity",
    "question": "What is the primary goal of design thinking?",
    "options": [
      "a) Following a strict methodology",
      "b) Solving problems from a human-centered perspective",
      "c) Creating highly technical solutions",
      "d) Eliminating innovation"
    ],
    "correctAnswer": "b) Solving problems from a human-centered perspective"
  },
  {
    "category": "Creativity",
    "question": "How can creative block be overcome?",
    "options": [
      "a) Taking a break and changing the environment",
      "b) Forcing ideas under pressure",
      "c) Avoiding collaboration",
      "d) Ignoring the problem"
    ],
    "correctAnswer": "a) Taking a break and changing the environment"
  }
];

export const eqQuestions:EQQuestions[]=[

    {
      "domain": "Self-Awareness",
      "question": "How often do you pause to reflect on your feelings before reacting?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Self-Awareness",
      "question": "When you feel upset, can you identify the specific cause?",
      "options": ["a) Always", "b) Most of the time", "c) Occasionally", "d) Hardly ever"]
    },
    {
      "domain": "Self-Awareness",
      "question": "How well do you recognize patterns in your emotional triggers?",
      "options": ["a) Very well", "b) Fairly well", "c) Somewhat", "d) Not at all"]
    },
    {
      "domain": "Self-Awareness",
      "question": "When you're stressed, do you notice physical signs such as tension or fatigue?",
      "options": ["a) Always", "b) Sometimes", "c) Rarely", "d) Never"]
    },
    {
      "domain": "Self-Awareness",
      "question": "How often do you find yourself acting on emotions without understanding them?",
      "options": ["a) Rarely", "b) Sometimes", "c) Often", "d) Always"]
    },
    {
      "domain": "Self-Awareness",
      "question": "Do you notice if your mood affects your decisions?",
      "options": ["a) Always", "b) Often", "c) Occasionally", "d) Never"]
    },
    {
      "domain": "Self-Awareness",
      "question": "Can you differentiate between various emotions, such as anger and frustration?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Self-Awareness",
      "question": "How easy is it for you to explain how you're feeling to others?",
      "options": ["a) Very easy", "b) Somewhat easy", "c) Difficult", "d) Very difficult"]
    },
    {
      "domain": "Self-Awareness",
      "question": "How often do you evaluate whether your actions align with your values?",
      "options": ["a) Frequently", "b) Occasionally", "c) Rarely", "d) Never"]
    },
    {
      "domain": "Self-Awareness",
      "question": "When you're wrong, how willing are you to admit it?",
      "options": ["a) Very willing", "b) Somewhat willing", "c) Hesitant", "d) Not willing"]
    },
    {
      "domain": "Other Awareness",
      "question": "How quickly can you sense when someone else is upset?",
      "options": ["a) Immediately", "b) Sometimes", "c) Rarely", "d) Never"]
    },
    {
      "domain": "Other Awareness",
      "question": "Do you notice changes in someone's tone of voice or body language?",
      "options": ["a) Always", "b) Often", "c) Occasionally", "d) Rarely"]
    },
    {
      "domain": "Other Awareness",
      "question": "How often do you consider how your actions affect others?",
      "options": ["a) Frequently", "b) Sometimes", "c) Rarely", "d) Never"]
    },
    {
      "domain": "Other Awareness",
      "question": "Do you recognize when someone feels left out or excluded?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Other Awareness",
      "question": "How often do you ask others how they're feeling?",
      "options": ["a) Frequently", "b) Sometimes", "c) Rarely", "d) Never"]
    },
    {
      "domain": "Other Awareness",
      "question": "Can you identify when someone is pretending to be fine but isn't?",
      "options": ["a) Very easily", "b) Often", "c) Occasionally", "d) Rarely"]
    },
    {
      "domain": "Other Awareness",
      "question": "When someone disagrees with you, do you try to understand their perspective?",
      "options": ["a) Always", "b) Most of the time", "c) Occasionally", "d) Rarely"]
    },
    {
      "domain": "Other Awareness",
      "question": "Do you notice subtle changes in a group’s atmosphere or dynamics?",
      "options": ["a) Always", "b) Often", "c) Occasionally", "d) Rarely"]
    },
    {
      "domain": "Other Awareness",
      "question": "How often do you misinterpret someone's emotions?",
      "options": ["a) Rarely", "b) Sometimes", "c) Often", "d) Frequently"]
    },
    {
      "domain": "Other Awareness",
      "question": "When someone is silent, do you try to understand what they might be feeling?",
      "options": ["a) Always", "b) Often", "c) Occasionally", "d) Rarely"]
    },
    {
      "domain": "Emotional Control",
      "question": "How well can you stay calm during stressful situations?",
      "options": ["a) Very well", "b) Fairly well", "c) Occasionally", "d) Poorly"]
    },
    {
      "domain": "Emotional Control",
      "question": "When you feel angry, how often do you take a moment before responding?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Emotional Control",
      "question": "Can you avoid snapping at others when you're stressed?",
      "options": ["a) Always", "b) Often", "c) Occasionally", "d) Rarely"]
    },
    {
      "domain": "Emotional Control",
      "question": "How often do you manage to stay positive during setbacks?",
      "options": ["a) Frequently", "b) Sometimes", "c) Rarely", "d) Never"]
    },
    {
      "domain": "Emotional Control",
      "question": "When you're overwhelmed, do you take steps to calm yourself down?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Emotional Control",
      "question": "How often do you regret saying something in the heat of the moment?",
      "options": ["a) Rarely", "b) Sometimes", "c) Often", "d) Frequently"]
    },
    {
      "domain": "Emotional Control",
      "question": "Can you shift your focus away from negative emotions to avoid dwelling on them?",
      "options": ["a) Easily", "b) Somewhat", "c) With difficulty", "d) Not at all"]
    },
    {
      "domain": "Emotional Control",
      "question": "How well do you handle unexpected changes?",
      "options": ["a) Very well", "b) Fairly well", "c) Occasionally", "d) Poorly"]
    },
    {
      "domain": "Emotional Control",
      "question": "When you're feeling impatient, how often do you try to relax?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Emotional Control",
      "question": "Can you recognize when you're about to lose control of your emotions?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Empathy",
      "question": "How often do you put yourself in someone else's shoes to understand their perspective?",
      "options": ["a) Frequently", "b) Occasionally", "c) Rarely", "d) Never"]
    },
    {
      "domain": "Empathy",
      "question": "Do you find it easy to relate to others' struggles?",
      "options": ["a) Always", "b) Often", "c) Occasionally", "d) Rarely"]
    },
    {
      "domain": "Empathy",
      "question": "When listening to someone, do you pay full attention without interrupting?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Empathy",
      "question": "How often do you validate others' feelings?",
      "options": ["a) Frequently", "b) Sometimes", "c) Rarely", "d) Never"]
    },
    {
      "domain": "Empathy",
      "question": "When someone shares a problem, do you focus on understanding rather than fixing?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Empathy",
      "question": "Can you sense when someone needs emotional support without them saying so?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Empathy",
      "question": "How often do you offer help without being asked?",
      "options": ["a) Frequently", "b) Occasionally", "c) Rarely", "d) Never"]
    },
    {
      "domain": "Empathy",
      "question": "Do you respect others' emotional boundaries?",
      "options": ["a) Always", "b) Often", "c) Occasionally", "d) Rarely"]
    },
    {
      "domain": "Empathy",
      "question": "How often do you notice when someone is feeling overwhelmed?",
      "options": ["a) Always", "b) Often", "c) Occasionally", "d) Rarely"]
    },
    {
      "domain": "Empathy",
      "question": "When someone feels judged, how often do you reassure them?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Wellbeing",
      "question": "How often do you prioritize your physical health through exercise or proper diet?",
      "options": ["a) Frequently", "b) Occasionally", "c) Rarely", "d) Never"]
    },
    {
      "domain": "Wellbeing",
      "question": "Do you make time for hobbies or activities that bring you joy?",
      "options": ["a) Always", "b) Often", "c) Sometimes", "d) Rarely"]
    },
    {
      "domain": "Wellbeing",
      "question": "How well do you maintain a healthy work-life balance?",
      "options": ["a) Very well", "b) Fairly well", "c) Occasionally", "d) Poorly"]
    },
    {
      "domain": "Wellbeing",
      "question": "When you're feeling overwhelmed, do you seek help or support?",
      "options": ["a) Always", "b) Often", "c) Occasionally", "d) Rarely"]
    },
    {
      "domain": "Wellbeing",
      "question": "How often do you check in with yourself to assess your emotional health?",
      "options": ["a) Frequently", "b) Occasionally", "c) Rarely", "d) Never"]
    }
]



