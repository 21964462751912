import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

interface ProgramHighlightProps {
  title: string;
  description: string;
}

export function ProgramHighlight({ title, description }: ProgramHighlightProps) {
  return (
    <div className="bg-black/5 p-4 sm:p-6 lg:p-8 rounded-3xl shadow-md transition-all duration-300 hover:shadow-xl max-w-full md:max-w-md lg:max-w-lg mx-auto mb-10">
      <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold mb-4 text-gray-800">
        {title}     
      </h3>
      <p className="text-sm sm:text-base lg:text-lg text-gray-600">{description}</p>
    </div>
  );
}
