import React from 'react';
import { Sun, Moon } from 'lucide-react';
import Jupiter from './Planets/jupiter.jpg';
import Uranus from './Planets/uranus.jpg';
import Mercury from './Planets/mercury.jpg';
import Venus from './Planets/venus.jpg';
import Neptune from './Planets/neptune.jpg';
import Saturn from './Planets/saturn.jpg';
import Mars from './Planets/mars.jpg';
import { useNavigate } from 'react-router-dom';

interface ProfileSectionProps {
  profile: string;
  fullName: string;
  dateOfBirth: string;
  gender: string;
}

interface Planet {
  name: string;
  icon: React.ReactNode;
}

function calculateDestinyNum(input: string): number {
  if (!input) return 0;

  const alphabetMap = (char: string) => (char.toUpperCase().charCodeAt(0) - 64) % 9 || 9;
  const sumDigits = (str: string) =>
    str
      .split('')
      .filter((char) => /[a-zA-Z]/.test(char))
      .map(alphabetMap)
      .reduce((acc, num) => acc + num, 0);

  const reduceToSingleDigit = (num: number) => {
    while (num > 9) {
      num = num
        .toString()
        .split('')
        .reduce((acc, digit) => acc + parseInt(digit, 10), 0);
    }
    return num;
  };

  const totalSum = sumDigits(input);
  return reduceToSingleDigit(totalSum);
}

function calculateDayNum(dateOfBirth: string): number {
  if (!dateOfBirth) return 0;

  // Extract the day from the date (last two characters after the last '-')
  const day = dateOfBirth.split('-').pop();
  if (!day) return 0; // In case of an invalid date format

  // Convert day string into individual digits and sum them
  let sum = day.split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);

  // Reduce to a single digit
  while (sum > 9) {
    sum = sum.toString().split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);
  }
  
  return sum;
}

function calculateLifePathNum(dateOfBirth: string): number {
  if (!dateOfBirth) return 0;

  const digits = dateOfBirth.replace(/\D/g, '').split('');
  let sum = digits.reduce((acc, digit) => acc + parseInt(digit, 10), 0);

  while (sum > 9) {
    sum = sum.toString().split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);
  }

  return sum;
}

function getPlanetByLifePathNumber(lifePathNum: number): Planet {
  const planets: Planet[] = [
    { name: 'Sun', icon: <Sun /> },
    { name: 'Moon', icon: <Moon /> },
    { name: 'Jupiter', icon: <img src={Jupiter} alt="Jupiter" className="w-8 h-8 object-cover filter drop-shadow-lg rounded-full mix-blend-color-burn " /> },
    { name: 'Uranus', icon: <img src={Uranus} alt="Uranus" className="w-8 h-8 object-cover filter drop-shadow-lg rounded-full mix-blend-color-burn" /> },
    { name: 'Mercury', icon: <img src={Mercury} alt="Mercury" className="w-8 h-8 object-cover filter drop-shadow-lg rounded-full mix-blend-color-burn" /> },
    { name: 'Venus', icon: <img src={Venus} alt="Venus" className="w-8 h-8 object-cover rounded-full filter drop-shadow-lg mix-blend-color-burn " /> },
    { name: 'Neptune', icon: <img src={Neptune} alt="Neptune" className="w-8 h-8 object-cover rounded-full filter drop-shadow-lg mix-blend-color-burn" /> },
    { name: 'Saturn', icon: <img src={Saturn} alt="Saturn" className="w-8 h-8 object-cover rounded-full filter drop-shadow-lg mix-blend-color-burn" /> },
    { name: 'Mars', icon: <img src={Mars} alt="Mars" className="w-8 h-8 object-cover rounded-full filter drop-shadow-lg mix-blend-color-burn" /> },
  ];

  return planets[(lifePathNum - 1) % planets.length] || { name: 'Unknown', icon: <Sun /> };
}

export function ProfileSection({ profile, fullName, dateOfBirth, gender }: ProfileSectionProps) {
  const lifePathNum =calculateDayNum (dateOfBirth);
  const expressionNum = calculateDestinyNum(fullName || '');
  const destinyNum = calculateLifePathNum(dateOfBirth);
  const planet = getPlanetByLifePathNumber(lifePathNum);
  const navigate=useNavigate()

  const defaultMaleImage =
  "https://images.unsplash.com/photo-1636377985931-898218afd306?fit=crop&w=150&h=150";
const defaultFemaleImage =
  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?fit=crop&w=150&h=150";

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-6 p-4">
      <div className="profile-section flex flex-col items-center justify-center w-full sm:w-1/2 p-4 rounded-3xl shadow-md">
        <div className="profile-pic-container w-24 h-24 sm:w-32 sm:h-32 relative">
          <div className="absolute inset-0 rounded-full animate-pulse-ring"></div>
          <div className="absolute inset-0 rounded-full animate-pulse-ring animation-delay-200"></div>
          <div className="absolute inset-0 rounded-full animate-pulse-ring animation-delay-400"></div>
          <img
            src={profile || (gender?.toLowerCase() === 'male' ? defaultMaleImage : defaultFemaleImage)}
            alt={fullName || 'User'}
            className="w-full h-full rounded-full object-cover"
          />
        </div>
        <h2 className="mt-4 text-xl sm:text-2xl font-bold text-white text-center">
          {fullName || 'User'}
        </h2>
      </div>

      <div
  className="info-section flex flex-col items-start justify-center w-full sm:w-1/2 p-4 rounded-3xl shadow-md transform hover:translate-y-2 hover:scale-105 hover:rotate-1 transition-all duration-300 ease-in-out"
  onClick={() => navigate('/astro')}
>
        <h1 className="text-lg sm:text-xl font-semibold text-white mb-2">Spiritual Blueprint</h1>
        <p className="text-sm sm:text-base text-white mt-2">
          <span className="font-medium text-white">Day Number:</span> {lifePathNum || 'N/A'}
        </p>
        <p className="text-sm sm:text-base text-white">
          <span className="font-medium text-white">Life Path Number:</span> {destinyNum}
        </p>
        <p className="text-sm sm:text-base text-white">
          <span className="font-medium text-white">Destiny Number:</span> {expressionNum || 'N/A'}
        </p>
      
        <div className="planet-section mt-1 flex items-center gap-4">
          <div className="relative group flex items-center gap-2">
            <span className="text-base sm:text-base text-white font-semibold">Planet(LP):</span>
            <div className="relative w-8 h-8 flex items-center justify-center bg-transparent rounded-full transition-all duration-300  ">
              {planet.icon}
            </div>
            <span className="absolute left-full ml-2 text-sm text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              {planet.name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
