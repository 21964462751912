import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
  data: Record<string, number>;
  colors: string[];
}

export function DoughnutChart({ data, colors }: DoughnutChartProps) {
  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        data: Object.values(data),
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true, // Ensure the chart maintains its aspect ratio
    responsive: true, // Enable responsive behavior
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  };

  return (
    <div className="w-full flex justify-center  h-[250px] md:h-[350px] lg:h-[400px]">
      <Doughnut data={chartData} options={options} />
    </div>
  );
}
