/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { Trophy } from 'lucide-react';
import { DoughnutChart } from '../Charts/DoughnutChart';
import { AssessmentForm } from './SkillsSectionForm';
import axios from 'axios';
import { baseURL } from '../../../config';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';

export function SkillsSection() {
  
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [isCoreSkillsTestTaken, setIsCoreSkillsTestTaken] = useState(false);
  const [isIqAssessmentTestTaken, setIsIqAssessmentTestTaken] = useState(false);
  const [isEqAssessmentTestTaken, setIsEqAssessmentTestTaken] = useState(false)

  const [showCoreSkillsForm, setShowCoreSkillsForm] = useState(false);
  const [showIqForm, setShowIqForm] = useState(false);
  const [showEqForm, setShowEqForm] = useState(false);

  const [iqScore, setIqScore] = useState<number | null>(null);
  const [coreSkillsData, setCoreSkillsData] = useState<{ category: string; score: number }[]>([]);
  const [eqScore,setEqScore]=useState<{ domain: string; score: number }[]>([]);



  const skillColors: Record<string, string> = {
    Communication: '#FF6384',
    ProblemSolving: '#36A2EB',
    Technical: '#FFCE56',
    Leadership: '#4BC0C0',
    Creativity: '#9966FF',
  };

  useEffect(() => {
    const fetchDataWithRetry = async () => {
      await fetchData();
      setTimeout(fetchData, 500); // Retry after 500ms
    };
  
    fetchDataWithRetry();
  }, []);

  const fetchData = async () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      // Optionally, retry after a short delay if userId is not yet available
      setTimeout(fetchData, 500);
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      const { data } = await axios.get(`${baseURL}/user/${userId}`);
      const { iqAssessment } = data;

      if (iqAssessment?.iqScore) {
        setIqScore(iqAssessment?.iqScore);
        setIsIqAssessmentTestTaken(true);
      } else {
        setIsIqAssessmentTestTaken(false);
      }
      
     

      if (iqAssessment?.coreSkillsData) {
        const skills = Object.entries(iqAssessment.coreSkillsData).map(([category, score]) => ({
          category,
          score: Number(score),
        }));
        setCoreSkillsData(skills);
        setIsCoreSkillsTestTaken(true);
      } else {
        setIsCoreSkillsTestTaken(false);
      }

      if (iqAssessment?.eqScore) {
        const skills = iqAssessment?.eqScore
        const parsedScores = skills.split("\n") .filter((line: string) => line.trim() && !line.includes("Domain Scores")) .map((line: { split: (arg0: string) => { (): any; new(): any; map: { (arg0: (part: string) => string): [any, any]; new(): any; }; }; }) => {
  const [key, value] = line.split(":").map((part: string) => part.trim()); 
  return { domain: key, score: Number(value) }; 
});
        setEqScore(parsedScores);
       setIsEqAssessmentTestTaken(true);
      } else {
       setIsEqAssessmentTestTaken(false);
      }
    
    } catch (err) {
      console.error('Error fetching user data:', err);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCoreSkillsSubmit = (result: { core?: { categoryScores?: Record<string, number> } }) => {
    if (result.core?.categoryScores) {
      const data = Object.entries(result.core.categoryScores).map(([category, score]) => ({
        category,
        score: Number(score),
      }));
      setCoreSkillsData(data);
      setIsCoreSkillsTestTaken(true);
      setShowCoreSkillsForm(false);
    }
  };

const handleIqSubmit = (result: { iq?: { totalScore?: number } }) => {
  if (result.iq?.totalScore) {
    // Update state with new IQ score
const score=result.iq?.totalScore
  setIqScore(score);
  setIsIqAssessmentTestTaken(true);
  setShowIqForm(false); // Close the form after submission
  }
};

const handlEqScoreSubmit = (result: { eq?: { categoryScores?: Record<string, number> } }) => {
  if (result.eq?.categoryScores) {
    const data = Object.entries(result.eq.categoryScores).map(([domain, score]) => ({
      domain,
      score: Number(score),
    }));
    setEqScore(data);
    setIsEqAssessmentTestTaken(true);
    setShowEqForm(false);
  }
};

  const getIqCategory = (score: number): string => {
    if (score <= 69) return 'Below Average';
    if (score >= 70 && score <= 85) return 'Low Average';
    if (score >= 86 && score <= 114) return 'Average';
    if (score >= 115 && score <= 129) return 'High Average';
    if (score >= 130) return 'Superior';
    return 'Unknown';
  };

  const coreSkillsDataChartData: Record<string, number> = coreSkillsData.reduce<Record<string, number>>((acc, { category, score }) => {
    acc[category] = score;
    return acc;
  }, {});

  if (isLoading) {
    return <div className="flex items-center justify-center h-64">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }


  return (
    <section id="skills" className="mb-8 px-4 sm:px-6 lg:px-0">
      <div className="flex items-center gap-4 mb-6">
        <div className="bg-yellow-100 p-3 rounded-full">
          <Trophy className="w-6 h-6 text-yellow-600" />
        </div>
        <h2 className="text-2xl font-semibold flex items-center">Master your skill, shape your future.</h2>
      </div>

      <div className="sm:grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Core Skills Section */}
        <Swiper
    spaceBetween={16}
    slidesPerView={1}
    className="block sm:hidden"
    modules={[Autoplay]} loop={false} autoplay={{delay: 3000,disableOnInteraction: true}}
  >
<SwiperSlide>
<div className={`bg-white p-6 rounded-3xl shadow-md border border-gray-200 relative ${!isCoreSkillsTestTaken ? 'opacity-50' : ''}`}>
          <h3 className="text-lg font-semibold mb-4 sm:text-xl">Core Skills</h3>
          {isCoreSkillsTestTaken ? (
            <DoughnutChart data={coreSkillsDataChartData} colors={Object.values(skillColors)} />
          ) : (
            <div className="h-48 flex items-center justify-center">
              <p className="text-lg text-gray-500">No data available</p>
            </div>
          )}
          {!isCoreSkillsTestTaken && (
            <button
              onClick={() => setShowCoreSkillsForm(true)}
              className="absolute top-4 right-4 px-3 py-1 text-sm bg-yellow-600 text-white rounded"
              aria-label="Take Core Skills Test"
            >
              Take Test
            </button>
          )}
        </div>
</SwiperSlide>

<SwiperSlide>
<div className={`bg-white p-6 rounded-3xl shadow-md border border-gray-200 relative ${!isIqAssessmentTestTaken ? 'opacity-50' : ''}`}>
          <h3 className="text-lg font-semibold mb-4 sm:text-xl">IQ Assessment</h3>
          <div className="flex items-center justify-center h-48">
            <div className="text-center">
              {iqScore !== null ? (
                <>
                  <p className="text-4xl font-bold text-yellow-600 sm:text-5xl">{iqScore}</p>
                  <p className="text-gray-500 mt-2 text-sm sm:text-base">IQ Score</p>
                  <p className="text-gray-500 mt-2 text-sm sm:text-base">{getIqCategory(iqScore)}</p>
                </>
              ) : (
                <p className="text-lg text-gray-500">No data available</p>
              )}
            </div>
          </div>
          {!isIqAssessmentTestTaken && (
            <button
              onClick={() => setShowIqForm(true)}
              className="absolute top-4 right-4 px-3 py-1 text-sm bg-yellow-600 text-white rounded"
              aria-label="Take IQ Test"
            >
              Take Test
            </button>
          )}
        </div>
</SwiperSlide>

<SwiperSlide>
<div className={`bg-white p-6 rounded-3xl shadow-md border border-gray-200 relative ${!isEqAssessmentTestTaken ? 'opacity-50' : ''}`}>
  <h3 className="text-lg font-semibold mb-4 sm:text-xl">EQ Assessment</h3>
  <div className="h-48 w-full">
    <div className="text-center">
      {eqScore.length === 0 ? (
        <div className="flex justify-center items-center">
          <div className="w-8 h-8 border-4 border-t-4 border-yellow-600 rounded animate-spin"></div> {/* Loader */}
        </div>
      ) : (
        <div className="flex flex-col space-y-3">
          {eqScore.map((item, index) => (
            <div key={index} className="flex justify-between items-center  rounded-lg">
              <p className="text-gray-700 font-semibold">{item.domain}</p>
              <p className="text-lg font-bold text-gray-900">{item.score}/40</p>
            </div>
          ))}
          
        </div>
        

      )}
    </div>
  </div>
  {!isEqAssessmentTestTaken && (
    <button
      onClick={() => setShowEqForm(true)}
      className="absolute top-4 right-4 px-3 py-1 text-sm bg-yellow-600 text-white rounded"
      aria-label="Take EQ Test"
    >
      Take Test
    </button>
  )}
</div>
</SwiperSlide>
  </Swiper>
   </div>
   
  <div className="hidden lg:grid lg:grid-cols-2 lg:gap-6 lg:w-full">
    {/* Core Skills Assessment Section */}
        <div className={`bg-white p-6 rounded-3xl shadow-md border border-gray-200 relative ${!isCoreSkillsTestTaken ? 'opacity-50' : ''}`}>
          <h3 className="text-lg font-semibold mb-4 sm:text-xl">Core Skills</h3>
          {isCoreSkillsTestTaken ? (
            <DoughnutChart data={coreSkillsDataChartData} colors={Object.values(skillColors)} />
          ) : (
            <div className="h-48 flex items-center justify-center">
              <p className="text-lg text-gray-500">No data available</p>
            </div>
          )}
          {!isCoreSkillsTestTaken && (
            <button
              onClick={() => setShowCoreSkillsForm(true)}
              className="absolute top-4 right-4 px-3 py-1 text-sm bg-yellow-600 text-white rounded"
              aria-label="Take Core Skills Test"
            >
              Take Test
            </button>
          )}
        </div>

<div className='lg:rows-span-2 lg:grid lg:gap-6'>
      {/* IQ Assessment Section */}
        <div className={`bg-white p-6 rounded-3xl shadow-md border border-gray-200 relative ${!isIqAssessmentTestTaken ? 'opacity-50' : ''}`}>
          <h3 className="text-lg font-semibold mb-4 sm:text-xl">IQ Assessment</h3>
          <div className="flex items-center justify-center h-48">
            <div className="text-center">
            {iqScore === null ? (
        <div className="flex justify-center items-center">
          <div className="w-8 h-8 border-4 border-t-4 border-yellow-600 rounded animate-spin"></div> {/* Loader */}
        </div>
      ) : (
        <>
          <p className="text-4xl font-bold text-yellow-600 sm:text-5xl">{iqScore}</p>
          <p className="text-gray-500 mt-2 text-sm sm:text-base">IQ Score</p>
          <p className="text-gray-500 mt-2 text-sm sm:text-base">{getIqCategory(iqScore)}</p>
        </>
      )}
            </div>
          </div>
          {!isIqAssessmentTestTaken &&(
            <button
              onClick={() => setShowIqForm(true)}
              className="absolute top-4 right-4 px-3 py-1 text-sm bg-yellow-600 text-white rounded"
              aria-label="Take IQ Test"
            >
              Take Test
            </button>
          )}
        </div>

        {/* EQ Assessment Section */}
        <div className={`bg-white p-6 rounded-3xl shadow-md border border-gray-200 relative ${!isEqAssessmentTestTaken ? 'opacity-50' : ''}`}>
  <h3 className="text-lg font-semibold mb-4 sm:text-xl">EQ Assessment</h3>
  <div className="h-48 w-full">
    <div className="text-center">
      {eqScore.length === 0 ? (
        <div className="flex justify-center items-center">
          <div className="w-8 h-8 border-4 border-t-4 border-yellow-600 rounded animate-spin"></div> {/* Loader */}
        </div>
      ) : (
        <div className="flex flex-col space-y-3">
          {eqScore.map((item, index) => (
            <div key={index} className="flex justify-between items-center  rounded-lg">
              <p className="text-gray-700 font-semibold">{item.domain}</p>
              <p className="text-lg font-bold text-gray-900">{item.score}/40</p>
            </div>
          ))}
          
        </div>
        

      )}
    </div>
  </div>
  {!isEqAssessmentTestTaken && (
    <button
      onClick={() => setShowEqForm(true)}
      className="absolute top-4 right-4 px-3 py-1 text-sm bg-yellow-600 text-white rounded"
      aria-label="Take EQ Test"
    >
      Take Test
    </button>
  )}
</div>


  </div>  

      </div>

      {/* Forms */}
      {showCoreSkillsForm && (
        <AssessmentForm
          type="core"
          onSubmit={handleCoreSkillsSubmit}
          onClose={() => setShowCoreSkillsForm(false)}
        />
      )}

      {showIqForm && (
        <AssessmentForm
          type="iq"
          onSubmit={handleIqSubmit}
          onClose={() => setShowIqForm(false)}
        />
      )}
          {showEqForm && (
        <AssessmentForm
          type="eq"
          onSubmit={handlEqScoreSubmit}
          onClose={() => setShowEqForm(false)}
        />
      )}

    </section>
  );
}
