'use client'

import { useState } from "react"
import { Modal } from "antd"
import startUp from '../../../../images/start-up-1.webp'
import { CriteriaCard } from "./Criteria"
import { ProgramHighlight } from "./ProgramHighlights"
import { ProcessStep } from "./ProcessStep"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Sidebar } from "../../Sidebar/Sidebar"
;
import { Autoplay } from "swiper/modules"

interface ModalContent {
  title: string
  description: string[]
}

export default function Incubation() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<ModalContent[]>([])
  const [modalTitle, setModalTitle] = useState<string>('')

  const criteriaData = [
    {
      number: '1',
      iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/9f79eec10559e2ff88e9957fe86f2e852c0d2d1103496fdb8dc18b084fdcd629',
      description: 'Enrollment in the program is available to students in any academic field.',
      colorClass: 'bg-red-500'
    },
    {
      number: '2',
      iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/b19dcf955e235cf126f6c90e177c761793e6b182919c6e2c4bde78026c98c59a',
      description: 'We are only looking for founders from PAN India',
      colorClass: 'bg-yellow-500'
    },
    {
      number: '3',
      iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/c0842a85a5c95f029b6c072f56e03410995e7734b9c9eac05a8adbc3095ad56f',
      description: 'If you are graduated.',
      colorClass: 'bg-green-900'
    }
  ]

  const programHighlights = [
    {
      title: "Expert-Led Workshops & Training",
      description: "Learn from successful entrepreneurs and industry experts who will share their insights and experiences with you."
    },
    {
      title: "One-on-One Mentorship",
      description: "Get personalized guidance from seasoned mentors who will help you navigate the challenges of starting and growing a business."
    },
    {
      title: "Access to Funding",
      description: "Discover funding opportunities and pitch your startup to potential investors."
    },
    {
      title: "Incubation & Growth Support",
      description: "Access to incubation resources to help you accelerate your startup's growth."
    },
    {
      title: "Community & Networking",
      description: "Build meaningful connections with like-minded students, industry professionals, and investors."
    }
  ]

  const processSteps = [
    'Application Process',
    'Paid Pre-Incubation (21-Day Activity Program)',
    'Cohort-Based Selection',
    'Post-Selection',
    'Continuous Evaluation & Progression',
  ]

  const handleOpenModal = (content: string) => {
    if (content === 'Application Process') {
      setModalContent([
        {
          title: 'Online Application Form:',
          description: ['Prospective applicants fill out an online form detailing their background, interests, experience, and goals. They will also need to provide information regarding their current business idea (if applicable), career goals, and niche areas they are passionate about.'],
        },
        {
          title: 'Initial Screening:',
          description: [
            'Applications are reviewed to ensure basic eligibility (age, career stage, etc.).',
            'Shortlisted candidates will receive access to the PreIncubation Activity Program.',
          ],
        },
      ]);
      setModalTitle(content);
    }
    else if (content === 'Paid Pre-Incubation (21-Day Activity Program)') {
      setModalContent([
        {
          title: 'Niche Identification',
          description: [
            'Self-assessment: Applicants undergo personality and skills assessments to discover their core strengths, values, and interests.',
            'Niche Exploration: Interactive modules to explore various niches based on the individual’s passion and potential market opportunities',
            'Mentor CheckIn: Each applicant has a 1:1 virtual session with a mentor (using Eklavya) to discuss their identified niche.'
          ],
        },
        {
          title: 'Idea Development & Validation',
          description: [
            'Problem Identification: Identify a problem in the chosen niche.',
            'Solution Building: Develop a basic business idea or solution to address the problem.',
            'Validation Test: Applicants perform a preliminary validation of their idea by conducting market research or gathering feedback from potential customers.',
            'Weekly Mentor CheckIn: Review findings and refine the idea with mentor guidance.',
          ],
        },
        {
          title: 'Understanding Market and Execution',
          description: [
            'Market Research: Conduct indepth research on the market demand for the proposed solution. ',
            'Competition Analysis: Evaluate competitors and refine the unique value proposition (UVP).',
            'Basic Business Model: Draft a basic business model outlining how the solution will generate revenue and how it will be executed',
            'Final Presentation: Prepare and submit a report summarizing the 21-days experience, including niche identification, idea validation, market research, and potential next steps',
          ],
        },
      ]);
      setModalTitle(content);
    }
    else if (content === 'Cohort-Based Selection') {
      setModalContent([
        {
          title: 'Entrepreneur (100/80%)',
          description: [
            'These applicants demonstrated a clear understanding of their niche, validated their idea successfully, and showed readiness to launch a business',
            'Path: Full support for building and scaling their startup (funding, mentorship, business development)',
          ],
        },
        {
          title: 'Founder Team (80/65%)',
          description: [
            'These applicants have a solid idea and niche but need a cofounder or team to help in execution and scaling.',
            'Path: Provided resources to build or join a founding team, with access to mentorship and teambuilding opportunities.',
          ],
        },
        {
          title: 'Freelancer (65/45%)',
          description: [
            'Applicants showed potential but need more time or refinement in their niche or idea development. ',
            'Path: Support to build freelance opportunities within their niche with continued access to niche-specific resources and mentoring.',
          ],
        },
      ]);
      setModalTitle(content);
    }
    else if (content === 'Post-Selection') {
      setModalContent([
        {
          title: 'Entrepreneur',
          description: [
            'Join the Entrepreneur Incubation Program, receiving 1:1 mentoring, access to funding, business development services, and coworking spaces.',
            'Focus on scaling their business and going to market within 6–12 months',
          ],
        },
        {
          title: 'Founder Team',
          description: [
            'Access to the Founder Development Program, where they can meet other participants to form teams, brainstorm ideas, and validate business concepts with continued mentor support.',
            'Matching with cofounders based on complementary skills.',
          ],
        },
        {
          title: 'Freelancer',
          description: [
            'Enrollment in the Freelancer Growth Program, providing access to learning modules for niche skills, project-building opportunities, and freelance gigs. ',
            'Continued access to mentors and resources to sharpen their niche expertise and develop sustainable freelance businesses.',
          ],
        },
      ]);
      setModalTitle(content);
    }
    else if (content === 'Continuous Evaluation & Progression') {
      setModalContent([
        {
          title: 'Continuous Evaluation & Progression',
          description: [
            'All participants will undergo periodic assessments and mentorship throughout their journey.',
            'Progression from Freelancer to Founder Team or Entrepreneur tracks is possible based on performance and readiness.',
          ],
        },
        {
          title: 'Founder Team',
          description: [
            'Access to the Founder Development Program, where they can meet other participants to form teams, brainstorm ideas, and validate business concepts with continued mentor support.',
            'Matching with cofounders based on complementary skills.',
          ],
        },
        {
          title: 'Freelancer',
          description: [
            'Enrollment in the Freelancer Growth Program, providing access to learning modules for niche skills, project-building opportunities, and freelance gigs. ',
            'Continued access to mentors and resources to sharpen their niche expertise and develop sustainable freelance businesses.',
          ],
        },
      ]);
      setModalTitle(content);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setModalContent([])
    setModalTitle('')
  }

  return (
<div className="min-h-screen p-4 bg-gradient-to-br from-blue-50 to-purple-50">
  <Sidebar/>
  <header className="py-5">
    <h2 className="text-4xl sm:text-6xl lg:text-7xl font-extrabold text-center leading-tight tracking-tight">
      <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-purple-500 to-pink-500 animate-gradient">
        Welcome to
      </span>
      <span className="block text-gray-900">
        21 Days Incubation Program
      </span>
    </h2>
  </header>

  <section className="py-12 sm:py-16 px-6 sm:px-12 lg:px-24 max-w-7xl mx-auto">
  <h2 className="text-3xl sm:text-5xl font-bold text-center mb-10 text-gray-800 relative group">
  <span className="relative after:content-[''] after:absolute after:-bottom-1 after:left-0 after:w-0 after:h-[2px] after:bg-purple-400 after:transition-all after:duration-300 group-hover:after:w-full">
    Who it is for?
  </span>
</h2>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      {criteriaData.map((criteria, index) => (
        <div
          key={index}
          className=" p-6 rounded-lg shadow-md hover:shadow-lg transform transition hover:-translate-y-1"
        >
          <CriteriaCard {...criteria} />
        </div>
      ))}
    </div>
  </section>

  <section className="py-12 sm:py-16 px-6 sm:px-12 lg:px-24 max-w-7xl mx-auto  rounded-xl">
  <h2 className="text-3xl sm:text-5xl font-bold text-center mb-10 text-gray-800 relative group">
  <span className="relative after:content-[''] after:absolute after:-bottom-1 after:left-0 after:w-0 after:h-[2px] after:bg-pink-400 after:transition-all after:duration-300 group-hover:after:w-full">
    Program Highlights
  </span>
</h2>
<Swiper
  spaceBetween={30}
  slidesPerView={1}
  pagination={{ clickable: true }}
  loop={true}
  autoplay={{
    delay: 3000,
    disableOnInteraction: false,
  }}
  modules={[Autoplay]}
>
      {programHighlights.map((highlight, index) => (
        <SwiperSlide key={index}>
          <ProgramHighlight {...highlight} />
        </SwiperSlide>
      ))}
    </Swiper>
  </section>

  <section className="py-12 sm:py-16 px-6 sm:px-12 lg:px-24 max-w-7xl mx-auto">
  <h2 className="text-3xl sm:text-5xl font-bold text-center mb-10 text-gray-800 relative group">
  <span className="relative after:content-[''] after:absolute after:-bottom-1 after:left-0 after:w-0 after:h-[2px] after:bg-blue-400 after:transition-all after:duration-300 group-hover:after:w-full">
    How it works ?
  </span>
</h2>
    <div className="flex flex-col lg:flex-row items-center gap-10">
      <div className="lg:w-1/2">
        <img
          src={startUp || "/placeholder.svg"}
          alt="How it works"
          className="w-full max-w-sm lg:max-w-full mx-auto rounded-lg shadow-lg hover:shadow-2xl transition duration-300 "
        />
      </div>
      <div className="lg:w-1/2 space-y-8">
        {processSteps.map((step, index) => (
          <ProcessStep
            key={index}
            title={step}
            onClick={() => handleOpenModal(step)}
          />
        ))}
      </div>
    </div>
  </section>

  <div className="mt-16 text-center">
    <button
      onClick={() => handleOpenModal("Application Process")}
      className="bg-gradient-to-r from-blue-600 via-purple-500 to-pink-500 text-white py-2 px-5 rounded-lg text-xl font-semibold hover:bg-gradient-to-l hover:scale-105 transition-transform duration-300"
    >
      Apply Now
    </button>
  </div>

  <Modal
    title={<div className="text-3xl font-bold text-center">{modalTitle}</div>}
    open={isModalOpen}
    onCancel={handleCloseModal}
    footer={null}
    width={800}
    style={{ top: "10vh" }}
    className="rounded-lg shadow-lg"
  >
    {modalContent.map((content, index) => (
      <div
        key={index}
        className="p-4 mb-4 bg-gray-50 border border-gray-200 rounded-lg shadow hover:shadow-md transition"
      >
        <h3 className="text-lg font-semibold mb-2 text-gray-900">
          {content.title}
        </h3>
        <ul className="list-disc pl-5 space-y-2">
          {content.description.map((desc, i) => (
            <li key={i} className="text-gray-700">
              {desc}
            </li>
          ))}
        </ul>
      </div>
    ))}
  </Modal>
</div>
  )
}

