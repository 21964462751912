import { Sidebar } from './Sidebar/Sidebar';
import { CalendarSection } from './CalendarSection';
import { PersonalDetails } from './PersonalDetails/PersonalDetails';
import { PersonalitySection } from './PersonalitySection/PersonalitySection';
import { HealthSection } from './HealthSection/HealthSection';
import { SkillsSection } from './SkillsSection/SkillsSection';
import { Courses } from './CoursesSection/Courses';
import { RoadmapSection } from './RoadmapSection/RoadmapSection';
import { useEffect, useState } from 'react';
import InsightHubSection from './InsightHubSection/InsightHubSection';
import { Bell, Search } from 'lucide-react';
import { baseURL } from '../../config';
import SearchProfile from '../../pages/Person-Profile/PersonProfile';
import axios from 'axios';
import SearchBar from './AnswerBook/AnswerBook';


// Define the user interface
interface User {
  _id: string;
  profilePic: string; // Profile picture at the top level
  personalDetails: {
    fullName: string;
    emailAddress: string;
    gender:string
  };
}

interface Notification{
  profilePic: string; // Profile picture at the top level
  personalDetails: {
    fullName: string;
    gender:string;
  };
}

const Dashboard = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isBellOpen, setIsBellOpen] = useState(false);

useEffect(()=>{
handleUserInfo()
},[])

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);

    if (e.target.value.trim() === '') {
      setSearchResults([]);
      setError('');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(
        `${baseURL}/user/search-user?fullName=${encodeURIComponent(e.target.value.trim())}`
      );
      if (!response.ok) {
        if (response.status === 404) {
          setSearchResults([]);
          setError('No users found with the given name.');
        } else {
          throw new Error('Failed to fetch search results.');
        }
      } else {
        const data: User[] = await response.json();
        setSearchResults(data);
        setError(''); // Clear any previous error
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserInfo= async()=>{
    const userId=localStorage.getItem("userId")
    try {
      const res=await axios.get(`${baseURL}/user/${userId}`)
      handleFollowersName(res.data.followers)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFollowersName = async (followers:string[]) => {
    try {
      const followerData = await Promise.all(
        followers.map(async (followerId) => {
          const res = await axios.get(`${baseURL}/user/${followerId}`);
          return res.data; // Assuming the response contains follower details in `res.data`
        })
      );
      // console.log(followerData)
      setNotifications(followerData); // Logs all follower data
    } catch (error) {
      console.error("Error fetching followers:", error);
    }
  };

  const handleUserClick = (id: string) => {
    setSelectedUserId(id);
  };
// console.log(selectedUserId)



const toggleBell = () => {
  setIsBellOpen(!isBellOpen);
};

  return (
<div className="flex flex-col md:flex-row bg-gray-50">
      <Sidebar />
      <main className="flex-1 p-4 md:p-8 md:ml-16">
        
        {selectedUserId ? (
          <SearchProfile userId={selectedUserId} />
        ) : (
          <>
            <div className="mb-4 flex mt-5 lg:justify-center justify-center lg:items-center items-center">
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                  className="p-2 pl-5 border border-gray-300 rounded-full lg:w-[90%] w-[80%]"
                />
                <Search className="absolute top-1/2 lg:right-40 right-24 transform -translate-y-1/2 text-gray-500" />
                <div>
                  <Bell
                    className="absolute top-1/2 lg:right-3 right-10 transform -translate-y-1/2 text-gray-500 cursor-pointer"
                    onClick={toggleBell}
                  />
                  {notifications.length > 0 && (
                    <span className="absolute lg:-top-1 -top-0 lg:-right-1 right-7 bg-red-500 text-white text-xs font-bold lg:px-2 px-1 py-0 lg:py-1 rounded-full">
                      {notifications.length}
                    </span>
                  )}
                </div>
              </div>
              {isBellOpen && notifications.length > 0 && (
                <div className="absolute z-10 top-24 right-5 bg-white border border-gray-300 rounded-lg shadow-lg p-4">
                  <h4 className="font-semibold text-gray-700">Notifications</h4>
                  <ul className="mt-2">
                  {notifications.map((notification, index) => (
  <li key={index} className='flex items-center gap-2 mb-2' >
    <img src={notification.profilePic||(notification.personalDetails.gender.toLowerCase() === "male"?"https://images.unsplash.com/photo-1636377985931-898218afd306?fit=crop&w=120&h=120":"https://images.unsplash.com/photo-1494790108377-be9c29b29330?fit=crop&w=120&h=120")} alt="" className="w-7 h-7 sm:w-12 sm:h-12 rounded-full object-cover" />
    <p className="text-gray-600 text-sm"><strong>{notification.personalDetails.fullName}</strong> started following you.</p>
  </li>
))}
                  </ul>
                </div>
              )}
            </div>

            {/* Search Results */}
            <div className="mb-6">
              {isLoading && <p>Loading...</p>}
              {searchResults.length === 0 && !isLoading && searchQuery.trim() !== "" && (
                <p className="text-red-500">{error}</p>
              )}
              {searchResults.length > 0 && (
                <ul className="mt-4 bg-white border border-gray-300 rounded-lg shadow-lg p-4">
                  {searchResults.map((user, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        handleUserClick(user._id); // User click handler
                      
                      }}
                      className="flex items-center mb-4 p-3 rounded-lg hover:bg-gray-100 transition duration-200 cursor-pointer"
                    >
                      {/* Profile Picture */}
                      <img
                        src={
                          user.profilePic ||(user.personalDetails.gender.toLowerCase() === "male"?"https://images.unsplash.com/photo-1636377985931-898218afd306?fit=crop&w=150&h=150":"https://images.unsplash.com/photo-1494790108377-be9c29b29330?fit=crop&w=150&h=150")
                          
                        }
                        alt={`${user.personalDetails.fullName}'s profile`}
                        className="w-14 h-14 rounded-full mr-4 border-2 border-blue-500"
                      />
                      {/* User Details */}
                      <div>
                        <p className="font-semibold text-lg text-gray-800">
                          {user.personalDetails.fullName}
                        </p>
                        <p className="text-sm text-gray-500">
                          {user.personalDetails.emailAddress}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Sections */}
            <PersonalDetails />
            <PersonalitySection />
            <HealthSection />
            <SkillsSection />
            <Courses />
            <SearchBar/>
            <RoadmapSection />
            <InsightHubSection />
            <CalendarSection />
          </>
        )}
      </main>
    </div>
  );
};

export default Dashboard;








// type drawerMenuItems= 
    // | { key: string; label: string; icon: JSX.Element; onClick: () => void } // With onClick
    // | { key: string; label: string; icon: JSX.Element };

    // const navMenuItems = [
    //     { key: '/', label: 'Dashboard', icon: <CalendarOutlined /> },
    //     ...(user.role === 'super-admin'
    //         ? [{ key: '/marketing-dashboard', label: 'Marketing Dashboard', icon: <PieChartOutlined /> }]
    //         : []),
    //     { key: '/assessment/projects', label: 'Projects', icon: <FileTextOutlined /> },
    //     ...(user.role !== 'freelancer'
    //         ? [
    //               { key: '/users/admin', label: 'Admin', icon: <UserOutlined /> },
    //               { key: '/users/staff', label: 'Staff', icon: <UserOutlined /> },
    //           ]
    //         : []),
    //     { key: '/insight-hub', label: "Insight's Hub", icon: <FileTextOutlined /> },
    // ];

    // const drawerMenuItems = [
    //     ...navMenuItems,
    //     { key: 'profile', label: 'Profile', icon: <UserOutlined />, onClick: () => navigate('/profile') },
    //     { key: 'logout', label: 'Logout', icon: <LogoutOutlined />, onClick: handleLogout },
    // ];

    // const toggleDrawer = () => {
    //     setIsDrawerOpen(!isDrawerOpen);
    // };

    // <Layout className="min-h-screen">
        //     <Header className="flex justify-between items-center border-b bg-white px-4 py-2 md:px-6 md:py-3 fixed top-0 left-0 right-0 z-20">
        //         <div className="flex items-center space-x-2 md:space-x-4">
        //             <img src={logo} alt="Logo" className="h-8 w-auto" />
        //             <div className="hidden lg:flex">
        //                 <Menu mode="horizontal" selectedKeys={[location.pathname]} className="flex-grow">
        //                     {navMenuItems.map((item) => (
        //                         <Menu.Item key={item.key} icon={item.icon}>
        //                             <Link to={item.key}>{item.label}</Link>
        //                         </Menu.Item>
        //                     ))}
        //                 </Menu>
        //             </div>
        //         </div>
        //         <div className="hidden lg:block">
        //             <Dropdown
        //                 overlay={
        //                     <Menu>
        //                         <Menu.Item key="profile" onClick={() => navigate('/profile')} icon={<UserOutlined />}>
        //                             Profile
        //                         </Menu.Item>
        //                         <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
        //                             Logout
        //                         </Menu.Item>
        //                     </Menu>
        //                 }
        //             >
        //                 <img
        //                     src={user.profilePic}
        //                     alt="Profile"
        //                     className="h-8 w-8 md:h-10 md:w-10 rounded-full object-cover cursor-pointer border border-white"
        //                 />
        //             </Dropdown>
        //         </div>
        //         <Button className="lg:hidden" type="text" icon={isDrawerOpen ? <CloseOutlined /> : <MenuOutlined />} onClick={toggleDrawer} />
        //     </Header>

        //     <Drawer
        //         title="Menu"
        //         placement="right"
        //         onClose={toggleDrawer}
        //         open={isDrawerOpen}
        //         className="lg:hidden"
        //     >
        //         <Menu mode="vertical" selectedKeys={[location.pathname]}>
        //             {drawerMenuItems.map((item) => (
        //                 <Menu.Item key={item.key} icon={item.icon} onClick={(item as any).onClick || undefined}>
        //                     {item.label}
        //                 </Menu.Item>
        //             ))}
        //         </Menu>
        //     </Drawer>

        //     <Content className="p-2 md:p-2 flex-grow mt-[65px]">
        //         <Routes>
        //             <Route path="/dashboard" element={<DashboardContent />} />
        //             <Route path="/marketing-dashboard" element={<PrivateRoute><div>Marketing Dashboard</div></PrivateRoute>} />
        //             <Route path="/assessment/projects" element={<PrivateRoute><Project /></PrivateRoute>} />
        //             <Route path="/assessment/projects/:id" element={<PrivateRoute><ProjectDetail /></PrivateRoute>} />
        //             <Route path="/insight-hub" element={<PrivateRoute><Article /></PrivateRoute>} />
        //             <Route path="/profile/*" element={<PrivateRoute><Profile /></PrivateRoute>} />
        //         </Routes>
        //     </Content>
        // </Layout>

        
// import { useEffect, useState } from 'react';
// import {  message } from 'antd';
// import { Link, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
// import {
//     CalendarOutlined,
//     PieChartOutlined,
//     FileTextOutlined,
//     UserOutlined,
//     LogoutOutlined,
//     MenuOutlined,
//     CloseOutlined,
// } from '@ant-design/icons';
// import logo from '../images/logo.png';
// import { baseURL } from "../config";
// import axios from 'axios';
// import PrivateRoute from '../utils/PrivateRoute';
// import Article from '../pages/Insight Hub/Article';
// import Project from '../pages/Assignment/Project/Project';
// import ProjectDetail from '../pages/Assignment/Project/ProjectDetail';
// import Profile from '../pages/Profile/Profile';
// import DashboardContent from '../pages/DashboardContent';

// import { Courses } from './Courses';
// import InsightHubSection from './InsightHubSection';

// const { Header, Content } = Layout;

// interface User {
//     profilePic?: string;
//     role?: string;
// }

    // const location = useLocation();
    // const userId = localStorage.getItem('userId');
    // const [user, setUser] = useState<User>({});
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // const navigate = useNavigate();
