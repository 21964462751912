import { cn } from "../../../../lib/utils";
import { AnimatedList } from "../../../ui/animated-list";

interface Person {
  personalDetails: {
    fullName: string;
    gender: string;
  };
  profilePic: string;
}

interface PeopleSectionProps {
  people: Person[];
}

export function PeopleSection({ people }: PeopleSectionProps) {
  return (
    <div className="people-carousel relative w-full overflow-hidden py-4 sm:py-8">
      <h3 className="text-lg sm:text-xl font-semibold mb-4 sm:mb-6 text-center text-white">
        People You May Know
      </h3>
      <div
        className={cn(
          "relative flex lg:h-[150px] h-[300px] w-full flex-col gap-2 p-2 overflow-hidden"
        )}
      >
        <AnimatedList>
          {people?.map((person, index) => {
            const defaultMalePic =
              "https://images.unsplash.com/photo-1636377985931-898218afd306?fit=crop&w=150&h=150";
            const defaultFemalePic =
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?fit=crop&w=150&h=150";
            const profilePic =
              person.profilePic ||
              (person.personalDetails.gender.toLowerCase() === "male"
                ? defaultMalePic
                : defaultFemalePic);

            return (
              <div
                key={index}
                className="flex items-center gap-2 bg-black/5 rounded-full shadow-lg p-2"
              >
                <img
                  src={profilePic}
                  alt={`${person.personalDetails.fullName}`}
                  className="w-10 h-10 sm:w-12 sm:h-12 rounded-full object-cover"
                />
                <span className="text-black text-xs sm:text-sm font-medium">
                  {person.personalDetails.fullName}
                </span>
              </div>
            );
          })}
        </AnimatedList>
      </div>
    </div>
  );
}
