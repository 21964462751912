import { ChevronRightIcon } from "@heroicons/react/24/solid"

interface ProcessStepProps {
  title: string
  onClick: () => void
}

export function ProcessStep({ title, onClick }: ProcessStepProps) {
  return (
<button
  className="w-full sm:w-auto p-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-md flex justify-between items-center  duration-300 hover:shadow-lg hover:from-purple-700 hover:to-indigo-700 md:p-6 lg:p-2 hover:scale-105 hover:bg-blue-50  rounded-lg  cursor-pointer transition"
  onClick={onClick}
>
  <span className="text-base lg:text-lg md:text-xl font-normal">{title}</span>
  <ChevronRightIcon className="h-5 w-5 lg:h-5 lg:w-5 md:h-8 md:w-8" />
</button>

  )
}

