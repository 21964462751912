/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

interface PersonalityFormProps {
  questionsA: Array<{ question: string; options: string[] }>;
  questionsB: Array<{ question: string; options: string[] }>;
  onSubmit: (answers: Record<string, any>) => void;
  initialTestType: 'bigFive' | 'mbti';
}

const PersonalityForm: React.FC<PersonalityFormProps> = ({
  questionsA,
  questionsB,
  onSubmit,
  initialTestType,
}) => {
  const [currentTest, setCurrentTest] = useState<'bigFive' | 'mbti'>(initialTestType);
  const [answers, setAnswers] = useState<Record<string, any>>({
    bigFive: {},
    mbti: {},
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [bothTestsCompleted, setBothTestsCompleted] = useState(false);
  const [loading, setLoading] = useState(false); // Track loading state

  const questions = currentTest === 'bigFive' ? questionsA : questionsB;

  const handleAnswer = (answer: string) => {
    setAnswers((prev) => ({
      ...prev,
      [currentTest]: {
        ...prev[currentTest],
        [currentQuestionIndex]: answer,
      },
    }));

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // If we've completed the current test
      if (currentTest === 'bigFive' && !answers.mbti[0]) {
        // Switch to MBTI test if Big Five is completed
        setCurrentTest('mbti');
        setCurrentQuestionIndex(0);
      } else if (currentTest === 'mbti' && !answers.bigFive[0]) {
        // Switch to Big Five test if MBTI is completed
        setCurrentTest('bigFive');
        setCurrentQuestionIndex(0);
      } else {
        // Both tests are completed
        setBothTestsCompleted(true);
      }
    }
  };

  const handleFinalSubmit = async () => {
    setLoading(true); // Set loading state to true when submission starts
  onSubmit({
      bigFive: answers.bigFive,
      mbti: answers.mbti,   
    });
    setLoading(false); // Set loading state to false when submission is completed
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-10">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">
          {currentTest === 'bigFive' ? 'Big Five Personality Test' : 'MBTI Assessment'}
        </h2>

        {!bothTestsCompleted ? (
          <>
            <div className="mb-6">
              <p className="text-lg mb-4">{questions[currentQuestionIndex].question}</p>
              <div className="space-y-2">
                {questions[currentQuestionIndex].options.map((option, index) => (
                  <button
                    key={index}
                    className="w-full text-left p-3 rounded border border-gray-200 hover:bg-purple-50 hover:border-purple-300 transition-colors"
                    onClick={() => handleAnswer(option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
            <div className="hidden sm:block text-sm text-gray-500">
              Question {currentQuestionIndex + 1} of {questions.length}
            </div>
          </>
        ) : (
          <div className="text-center">
            <p className="text-lg mb-4">You have completed both assessments!</p>
            <button
              className="bg-purple-600 text-white px-6 py-2 rounded hover:bg-purple-700 transition-colors"
              onClick={handleFinalSubmit}
              disabled={loading} // Disable button during submission
            >
       {loading ? (
            <span className="flex justify-center items-center">
              <svg
                className="animate-spin h-5 w-5 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4v1m0 14v1m7-7h-1m-14 0H5m4.5 4.5L7.5 17m9 0l-2.5-2.5m-9-9l2.5 2.5M16.5 7.5L14 5"
                />
              </svg>
              Submitting...
            </span>
          ) : (
            'Submit All Responses'
          )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalityForm;
