import { Routes, Route, Navigate } from "react-router-dom";
import LoginForm from "../pages/LoginForm";
// import RegisterForm from "../pages/RegisterForm";
import ForgotPassword from "../pages/ForgotPassword";
import VerifyCode from "../pages/VerifyCode";
import ResetPassword from "../pages/Resetpassword";
// import PrivateRoute from "./PrivateRoute";
import Article from "../pages/Insight Hub/Article";
import Profile from "../pages/Profile/Profile";
import AllVideos from "../pages/AllVideos";
// import DashboardContent from "../pages/DashboardContent";
import FreelancerDashboard from "../components/Freelancer-Dashboard/Dashboard";
// import EntrepreneurDashboard from "../pages/Entreprenuer/Dashboard";
import ProjectDetail from "../pages/Assignment/Project/ProjectDetail";
import Project from "../pages/Assignment/Project/Project";
// import Projects from "../pages/Entreprenuer/Projects";
import Store from "../pages/Entreprenuer/Store";
import Team from "../pages/Entreprenuer/Team";
import Tasks from "../pages/Entreprenuer/Tasks";
// import Courses from "../pages/Entreprenuer/Courses";
import Classes from "../pages/Entreprenuer/Classes";
import AllBlogs from "../pages/DashboardContent";
import Incubation from "../components/Freelancer-Dashboard/RoadmapSection/Incubation-21/21DaysIncubation";
import Page from "../pages/Status";
import { Details } from "../components/Freelancer-Dashboard/PersonalDetails/Details";
import { Ebooks } from "../components/Freelancer-Dashboard/Ebooks/Ebooks";
import { Messages } from "../components/Freelancer-Dashboard/Messages/Messages";
import { Eklavya } from "../components/Freelancer-Dashboard/Eklavya/Eklavya";
// import Road from "../components/Freelancer-Dashboard/RoadmapSection/Incubations/Road";
// import SignupForm from "../components/OnBoarding-Form/SignupForm";
// import Form from "../components/OnBoarding-Form/Form";
// import DashboardContent from "../pages/Admin/DashboardContent";
// import VentureCapitalistDashboard from "../components/VentureCapitalist-Dashboard/Dashboard";
// import AdminDashboard from "../components/Admin-Dashboard/Dashboard";

const AllRoutes = () => {
    const isUser = localStorage.getItem("token") && localStorage.getItem("userId");
    // const userRole = localStorage.getItem("role");

    // Function to determine which dashboard to show
    // const getDashboardComponent = () => {
    //     switch (userRole) {
    //         case "freelancer":
    //             return <FreelancerDashboard />;
    //         case "entrepreneur":
    //             return <EntrepreneurDashboard />;
    //         case "venture_capitalist":
    //             return <EntrepreneurDashboard />;
    //         case "admin":
    //             return <DashboardContent/>;
    //     }
    // };


    // const getProjectComponent = () => {
    //     switch (userRole) {
    //         case "freelancer":
    //             return <Project/>;
    //         case "entrepreneur":
    //             return <Projects />;
    //     }
    // };

    // const getCoursesComponent = () => {
    //     switch (userRole) {
    //         case "freelancer":
    //             return <AllVideos/>;
    //         case "entrepreneur":
    //             return <Courses/>;
    //     }
    // };

    return (
        <Routes>
            <Route path="/" element={isUser ? <Navigate to="/dashboard" /> : <Navigate to='/signin' />} />
            <Route path="/signin" element={<LoginForm />} />
            {/* <Route path="/signup" element={<SignupForm/>} /> */}
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/insight-hub" element={<Article />} />
            <Route path="/store" element={<Store/>} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/project" element={<Project/>}/>
            <Route path="/assessment/projects/:id" element={<ProjectDetail/>} />
            <Route path="/verify-otp" element={<VerifyCode />} />
            <Route path="/courses" element={<AllVideos/>} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/dashboard/*" element={ <FreelancerDashboard/> } />
            <Route path="/team" element={<Team/>} />
            <Route path="/tasks" element={<Tasks/>} />
            <Route path="/classes" element={<Classes/>} />
            <Route path="/blogs" element={<AllBlogs/>} />
            <Route path="/21DaysIncubation" element={<Incubation/>} />
            <Route path="/status" element={<Page/>} />
            <Route path="/astro" element={<Details/>} />
            <Route path="/library" element={<Ebooks/>} />
            <Route path="/messages" element={<Messages/>} />
            <Route path="/eklavya" element={<Eklavya/>} />
            {/* <Route path="/incubation-details" element={<Road/>} /> */}

        </Routes>
    );
};

export default AllRoutes;
