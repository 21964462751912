import { Sidebar } from "../Sidebar/Sidebar";

export function Messages(){
    return(
        <div className="flex min-h-screen bg-gray-100">
            <Sidebar/>
            <div className="flex flex-1 items-center justify-center text-center p-6">
        <h1 className="text-4xl md:text-5xl font-semibold text-gray-800">
          Coming Soon!
        </h1>
      </div>
        </div>
    )
}