import { useState } from "react";
import { Search } from "lucide-react";
import { baseURL } from "../../../config";
import axios from "axios";
import ReactMarkdown from "react-markdown";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const [result, setResult] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSearch = async () => {
    if (query.trim() === "") {
      setResult(null);
      setError("Please enter a search query.");
      return;
    }

    setIsLoading(true);
    setResult(null);
    setError(null);

    try {
      const response = await axios.post(`${baseURL}/user/explain`,{query:query });
      setResult(response.data || "No results found.");
    } catch (error) {
      setError("Something went wrong. Please try again.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start mb-8">
      <div className={`w-full rounded-lg transition-all duration-300 ${result || isLoading ? "h-auto" : "h-[100px]"}`}>
        <h2 className="text-xl font-semibold text-left mb-4">Querypedia</h2>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="What do you want to know?"
            className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button
            onClick={handleSearch}
            className="p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none"
          >
            <Search className="w-5 h-5" />
          </button>
        </div>

        {isLoading && (
          <div className="flex justify-center items-center mt-4">
            <span className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-500"></span>
          </div>
        )}

        {error && <p className="text-red-500 mt-4">{error}</p>}

        {result && !isLoading && (
          <ReactMarkdown className="mt-4 bg-gray-200 rounded-lg p-5">
            {result}
          </ReactMarkdown>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
