import { Brain, User, LogOut, LayoutDashboard, MessageCircle, Book, Bot } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { MobileNav } from './MobileNav';
import { DesktopSidebar } from './DesktopNav';
import { type MenuItem } from './types';
import logo from '../../../images/favicon.png';
import { Modal } from 'antd';

export function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    Modal.confirm({
      title: 'Confirm Logout',
      content: 'Are you sure you want to log out?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('role');
        setTimeout(() => {
          navigate('/signin');
        }, 1000);
      },
    });
  };

  const menuItems: MenuItem[] = [
    { icon: LayoutDashboard, label: 'Home', href: '/' },
    { icon: User, label: 'Profile', href: '/profile' },
    { icon: Brain, label: 'Projects', href: '/project' },
    { icon: Book, label: 'Library', href: '/library' },
    { icon: Bot, label: 'Eklavya', href: '/eklavya' },
    { icon: MessageCircle, label: 'Messages', href: '/messages' },
    { icon: LogOut, label: 'Logout', onClick: handleLogout },
  ];

  const handleItemClick = (item: MenuItem) => {
    if (item.onClick) {
      item.onClick();
    } else if (item.href) {
      navigate(item.href);
    }
  };

  return (
    <>
      <DesktopSidebar
        menuItems={menuItems}
        logo={logo}
        onItemClick={handleItemClick}
      />
      <MobileNav
        menuItems={menuItems} 
        onItemClick={handleItemClick}
      />
    </>
  );
}
