import { TestType } from './type';

interface AssessmentProgressProps {
  type: TestType;
  answers: Record<TestType, string[]>;
  totalQuestions: {
    iq: number;
    core: number;
    eq:number;
  };
}

export function AssessmentProgress({ answers, totalQuestions }: AssessmentProgressProps) {
  const getProgress = (testType: TestType) => {
    const answered = answers[testType].filter(answer => answer !== undefined).length;
    const total = totalQuestions[testType];
    return Math.round((answered / total) * 100);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-center space-x-4">
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-500">IQ Test:</span>
          <div className="w-2 h-2 rounded-full bg-green-500"></div>
          <span className="text-sm text-gray-500">
            {getProgress('iq')}%
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-500">Core Skills:</span>
          <div className="w-2 h-2 rounded-full bg-yellow-500"></div>
          <span className="text-sm text-gray-500">
            {getProgress('core')}%
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-500">EQ Test:</span>
          <div className="w-2 h-2 rounded-full bg-yellow-500"></div>
          <span className="text-sm text-gray-500">
            {getProgress('eq')}%
          </span>
        </div>
      </div>
    </div>
  );
}