import { Sidebar } from "../Sidebar/Sidebar";

const namankNumbers = [
  {
    number: 1,
    title: "The Leader",
    description:
      "You have leadership qualities and the ability to innovate and inspire.",
    strengths: ["Initiative", "confidence", "originality"],
    challenges: "Avoid arrogance and work on teamwork skills.",
  },
  {
    number: 2,
    title: "The Diplomat",
    description:
      "You are naturally diplomatic and good at forming relationships.",
    strengths: ["Sensitivity", "cooperation", "patience"],
    challenges: "Work on being more decisive and assertive.",
  },
  {
    number: 3,
    title: "The Communicator",
    description:
      "You are expressive and creative, with talents in the arts and communication.",
    strengths: ["Creativity", "expressiveness", "charm"],
    challenges: "Focus on your goals and avoid being scattered.",
  },
  {
    number: 4,
    title: "The Builder",
    description: "You are methodical and dependable, with a strong work ethic.",
    strengths: ["Organization", "determination", "practicality"],
    challenges: "Embrace flexibility and avoid being overly rigid.",
  },
  {
    number: 5,
    title: "The Adventurer",
    description: "You thrive on change and excitement, with a zest for life.",
    strengths: ["Versatility", "curiosity", "enthusiasm"],
    challenges: "Balance your need for adventure with stability.",
  },
  {
    number: 6,
    title: "The Caregiver",
    description:
      "You are compassionate and responsible, with a strong sense of duty.",
    strengths: ["Nurturing", "supportiveness", "reliability"],
    challenges: "Set boundaries and avoid neglecting your own needs.",
  },
  {
    number: 7,
    title: "The Thinker",
    description:
      "You have deep intellectual and spiritual insights, often drawn to solitary pursuits.",
    strengths: ["Analytical thinking", "introspection", "wisdom"],
    challenges: "Engage more with others and avoid isolation.",
  },
  {
    number: 8,
    title: "The Achiever",
    description:
      "You are ambitious and focused on achieving success in your career and personal life.",
    strengths: ["Leadership", "determination", "efficiency"],
    challenges: "Balance ambition with personal relationships.",
  },
  {
    number: 9,
    title: "The Humanitarian",
    description:
      "You are compassionate and idealistic, with a desire to help others.",
    strengths: ["Generosity", "vision", "empathy"],
    challenges: "Stay grounded and practical in your efforts to help others.",
  },
];

const bhagyankNumbers = [
  {
    number: 1,
    title: "The Trailblazer",
    description:
      "You are destined to take on leadership roles and break new ground in your field.",
    strengths: ["Leadership", "innovation", "independence"],
    challenges: ["Avoid arrogance and learn to work with others"],
  },
  {
    number: 2,
    title: "The Collaborator",
    description:
      "Your destiny involves building relationships and fostering cooperation.",
    strengths: ["Diplomacy", "supportiveness", "teamwork"],
    challenges: ["Work on decision-making and assertiveness"],
  },
  {
    number: 3,
    title: "The Visionary",
    description:
      "Your life's path includes creative expression and sharing your ideas with the world.",
    strengths: ["Creativity", "communication", "optimism"],
    challenges: ["Manage your focus and avoid superficiality"],
  },
  {
    number: 4,
    title: "The Organizer",
    description:
      "You are meant to create order and structure in your life and work.",
    strengths: ["Planning", "diligence", "responsibility"],
    challenges: ["Embrace change and avoid being overly rigid"],
  },
  {
    number: 5,
    title: "The Explorer",
    description:
      "Your destiny involves seeking new experiences and embracing freedom.",
    strengths: ["Curiosity", "adaptability", "resourcefulness"],
    challenges: ["Balance your desire for change with consistency"],
  },
  {
    number: 6,
    title: "The Healer",
    description:
      "Your purpose is to provide care, support, and love to others.",
    strengths: ["Nurturing", "responsibility", "empathy"],
    challenges: [
      "Maintain personal boundaries and avoid overburdening yourself",
    ],
  },
  {
    number: 7,
    title: "The Scholar",
    description:
      "You are destined to seek knowledge and explore spiritual truths.",
    strengths: ["Insight", "analytical skills", "introspection"],
    challenges: ["Engage with the world and avoid isolation"],
  },
  {
    number: 8,
    title: "The Executive",
    description:
      "Your path involves achieving success through hard work and leadership.",
    strengths: ["Ambition", "discipline", "strategic thinking"],
    challenges: ["Balance material success with personal fulfillment"],
  },
  {
    number: 9,
    title: "The Philanthropist",
    description:
      "You are meant to serve humanity and work towards the betterment of society.",
    strengths: ["Generosity", "compassion", "vision"],
    challenges: ["Stay grounded and practical in your efforts"],
  },
];

const mulankNumbers = [
  {
    number: 1,
    name: "The Leader",
    description:
      "People with Mulank 1 are natural leaders. They are ambitious, confident, and innovative. They often take the initiative and are driven to succeed.",
    strengths: ["Leadership", "Creativity", "Independence"],
    challenges: ["Can be overly stubborn or self-centered"],
    advice:
      "Use your leadership skills to inspire others and balance your ambition with empathy.",
  },
  {
    number: 2,
    name: "The Peacemaker",
    description:
      "Those with Mulank 2 are diplomatic, sensitive, and cooperative. They seek harmony and work well in partnerships.",
    strengths: ["Diplomacy", "Intuition", "Cooperation"],
    challenges: ["Can be overly sensitive or indecisive"],
    advice:
      "Use your intuition to navigate conflicts and find peaceful solutions.",
  },
  {
    number: 3,
    name: "The Creative Communicator",
    description:
      "Mulank 3 individuals are creative, expressive, and optimistic. They have a talent for communication and often engage in artistic endeavors.",
    strengths: ["Creativity", "Charisma", "Communication"],
    challenges: ["Can be scattered or struggle with focus"],
    advice:
      "Channel your creativity into productive projects and avoid distractions.",
  },
  {
    number: 4,
    name: "The Builder",
    description:
      "Mulank 4s are practical, disciplined, and hardworking. They value stability and work diligently to build a secure future.",
    strengths: ["Organization", "Reliability", "Dedication"],
    challenges: ["Can be overly rigid or resistant to change"],
    advice:
      "Embrace flexibility and use your discipline to create lasting success.",
  },
  {
    number: 5,
    name: "The Adventurer",
    description:
      "Those with Mulank 5 are dynamic, curious, and freedom-loving. They seek variety and are drawn to travel and new experiences.",
    strengths: ["Adaptability", "Enthusiasm", "Curiosity"],
    challenges: ["Can be restless or prone to taking risks"],
    advice:
      "Seek a balance between your desire for adventure and the need for stability.",
  },
  {
    number: 6,
    name: "The Nurturer",
    description:
      "Mulank 6 individuals are caring, responsible, and family-oriented. They are natural caregivers and strive to create a harmonious home life.",
    strengths: ["Compassion", "Responsibility", "Nurturing"],
    challenges: ["Can be overprotective or self-sacrificing"],
    advice:
      "Maintain healthy boundaries and use your nurturing nature to support others.",
  },
  {
    number: 7,
    name: "The Seeker",
    description:
      "Mulank 7s are introspective, analytical, and spiritual. They seek knowledge and understanding of deeper truths.",
    strengths: ["Wisdom", "Intuition", "Analytical Thinking"],
    challenges: ["Can be withdrawn or overly critical"],
    advice:
      "Embrace your spiritual quest and seek balance in social interactions.",
  },
  {
    number: 8,
    name: "The Achiever",
    description:
      "Those with Mulank 8 are ambitious, determined, and focused on success. They excel in business and leadership roles.",
    strengths: ["Ambition", "Leadership", "Efficiency"],
    challenges: ["Can be materialistic or domineering"],
    advice:
      "Use your ambition for positive change and balance work with personal relationships.",
  },
  {
    number: 9,
    name: "The Humanitarian",
    description:
      "Mulank 9 individuals are compassionate, idealistic, and selfless. They are drawn to helping others and making a difference in the world.",
    strengths: ["Compassion", "Idealism", "Generosity"],
    challenges: ["Can be overly romantic or struggle with boundaries"],
    advice:
      "Focus on practical ways to positively impact and care for yourself.",
  },
];

export function Details() {
  return (
    <>
    <Sidebar/>
        <div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <h1 className="text-4xl font-bold text-center text-purple-800 mb-12">
        Astro Vocabulary
      </h1>

      {/* Day Number Section */}
      <section className="max-w-7xl mx-auto mb-20">
        <div className="md:flex gap-8 mb-16">
          <img
            src="https://astrodunia.com/wp-content/uploads/2024/07/moolank.jpg"
            alt="Day Number"
            className="md:w-1/2 rounded-lg shadow-lg object-cover h-80"
          />
          <div className="mt-6 md:mt-0">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">
              What is Day Number?
            </h2>
            <p className="text-gray-600 leading-relaxed">
              The Day (Moolank) Number, is derived from your birthdate. It
              reflects your core personality traits, life purpose, and key
              challenges. It’s considered the most significant number in
              numerology, giving insights into your life’s journey.
            </p>
          </div>
        </div>

        <div className="bg-white p-8 rounded-xl shadow-md">
          <h2 className="text-2xl font-bold text-gray-800 mb-8 border-b-2 border-purple-200 pb-4">
            Day Numbers and Their Meanings
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {mulankNumbers.map((num) => (
              <div
                key={num.number}
                className="bg-gray-50 p-6 rounded-lg hover:shadow-md transition-shadow"
              >
                <h3 className="text-xl font-semibold text-purple-600 mb-4">
                  Mulank {num.number}
                </h3>
                <div className="space-y-3">
                  <div>
                    <strong className="text-gray-700">Description:</strong>
                    <p className="text-gray-600 mt-1">{num.description}</p>
                  </div>
                  <div>
                    <strong className="text-gray-700">Strengths:</strong>
                    <p className="text-gray-600 mt-1">{num.strengths}</p>
                  </div>
                  <div>
                    <strong className="text-gray-700">Challenges:</strong>
                    <p className="text-gray-600 mt-1">{num.challenges}</p>
                  </div>
                  <div>
                    <strong className="text-gray-700">Advice:</strong>
                    <p className="text-gray-600 mt-1">{num.advice}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Life Path Number Section */}
      <section className="max-w-7xl mx-auto mb-20">
        <div className="md:flex gap-8 mb-16">
          <img
            src="https://astrodunia.com/wp-content/uploads/2024/07/numeroweek-1024x576.jpg"
            alt="Life Path Number"
            className="md:w-1/2 rounded-lg shadow-lg object-cover h-80"
          />
          <div className="mt-6 md:mt-0">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">
              What is Life Path Number?
            </h2>
            <p className="text-gray-600 leading-relaxed">
              The Life Path Number represents your life’s mission and potential
              achievements. Derived from your full birthdate, it reveals the
              lessons you're here to learn.
            </p>
          </div>
        </div>

        <div className="bg-white p-8 rounded-xl shadow-md">
          <h2 className="text-2xl font-bold text-gray-800 mb-8 border-b-2 border-purple-200 pb-4">
            Life Path Numbers Explained
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {bhagyankNumbers.map((num) => (
              <div key={num.number} className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-xl font-semibold text-purple-600 mb-3">
                  Bhagyank {num.number}
                </h3>
                <ul className="space-y-2 text-gray-600">
                  <li>
                    <strong>Title:</strong> {num.title}
                  </li>
                  <li>
                    <strong>Description:</strong>
                    {num.description}
                  </li>
                  <li>
                    <strong>Strengths:</strong>
                    {num.strengths.join(", ")}
                  </li>
                  <li>
                    <strong>Challenges:</strong>
                    {num.challenges}
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Destiny Number Section */}
      <section className="max-w-7xl mx-auto">
        <div className="md:flex gap-8 mb-16">
          <img
            src="https://astrodunia.com/wp-content/uploads/2024/07/namank-1024x576.jpg"
            alt="Name Number"
            className="md:w-1/2 rounded-lg shadow-lg object-cover h-80"
          />
          <div className="mt-6 md:mt-0">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">
              What is Destiny Number?
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Derived from your name, the Namank reveals your natural talents
              and the person you're becoming through its numerical significance.
            </p>
          </div>
        </div>

        <div className="bg-white p-8 rounded-xl shadow-md">
          <h2 className="text-2xl font-bold text-gray-800 mb-8 border-b-2 border-purple-200 pb-4">
            Destiny Number Meanings
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {namankNumbers.map((num) => (
              <div
                key={num.number}
                className="bg-gray-50 p-5 rounded-lg hover:translate-y-[-2px] transition-transform"
              >
                <h3 className="text-lg font-semibold text-purple-700 mb-2">
                  Namank {num.number}
                </h3>
                <div className="space-y-2 text-sm text-gray-600">
                  <p>
                    <strong className="text-gray-700">Title:</strong>{" "}
                    {num.title}
                  </p>
                  <p>
                    <strong className="text-gray-700">Traits:</strong>{" "}
                    {num.description}
                  </p>
                  <p>
                    <strong className="text-gray-700">Focus:</strong>{" "}
                    {num.strengths.join(", ")}
                  </p>
                  <p>
                    <strong className="text-gray-700">Advice:</strong>{" "}
                    {num.challenges}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
{/* Summary Section */}
      <section className="max-w-7xl mx-auto px-4 py-8 ">
      <div className="container mx-auto">
        <div className="overflow-x-auto ">
        <h1 className="text-2xl font-bold text-gray-800 mb-6 p-4  rounded-t-lg">
              Summary Table
            </h1>
          <table className="w-full">

            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-4 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider border-b-2 border-gray-200">
                  Number
                </th>
                <th className="px-6 py-4 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider border-b-2 border-gray-200">
                  Moolank 
                </th>
                <th className="px-6 py-4 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider border-b-2 border-gray-200">
                  Bhagyank 
                </th>
                <th className="px-6 py-4 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider border-b-2 border-gray-200">
                  Namank 
                </th>
                <th className="px-6 py-4 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider border-b-2 border-gray-200">
                  Ruled By 
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {[...Array(9)].map((_, i) => {
                const number = i + 1;
                const data = [
                  { moolank: 'Leader', bhagyank: 'Trailblazer', namank: 'Leader', planet:"Sun" },
                  { moolank: 'Peacemaker', bhagyank: 'Collaborator', namank: 'Diplomat', planet:"Moon" },
                  { moolank: 'Creative Communicator', bhagyank: 'Visionary', namank: 'Communicator', planet:"Jupiter" },
                  { moolank: 'Builder', bhagyank: 'Organizer', namank: 'Builder', planet:"Uranus" },
                  { moolank: 'Adventurer', bhagyank: 'Explorer', namank: 'Adventurer' , planet:"Mercury"},
                  { moolank: 'Nurturer', bhagyank: 'Healer', namank: 'Caregiver', planet:"Venus" },
                  { moolank: 'Seeker', bhagyank: 'Scholar', namank: 'Thinker', planet:"Neptune" },
                  { moolank: 'Achiever', bhagyank: 'Executive', namank: 'Achiever' , planet:"Saturn"},
                  { moolank: 'Humanitarian', bhagyank: 'Philanthropist', namank: 'Humanitarian', planet:"Mars" },
                ][i];

                return (
                  <tr key={number} className="hover:bg-gray-50 transition-colors">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {number}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {data.moolank}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {data.bhagyank}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {data.namank}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {data.planet}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
    </div>
    </>

  );
}
